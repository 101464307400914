<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <b-modal
          v-model="menu_abre"
          size="xl"
          title="Menu"
          hide-footer
          @hidden="back()"
        >
          <div class="card">
            <div class="card-body">
              <div class="row d-flex justify-content-center align-items-center">
                <div class="col-md-12 text-center">
                  <button id="largura" class="btn btn-primary">
                    Imprimir o Pedido
                  </button>
                </div>
              </div>
              <div class="row d-flex justify-content-center align-items-center">
                <div class="col-md-12 text-center">
                  <button
                    id="largura"
                    class="btn btn-primary"
                    @click="modal_abrir_estabelece()"
                  >
                    Estabilizar Pedido
                  </button>
                </div>
              </div>
              <div class="row d-flex justify-content-center align-items-center">
                <div class="col-md-12 text-center">
                  <button
                    id="largura"
                    class="btn btn-primary"
                    @click="faturando_modal_abrir()"
                  >
                    Faturar o Pedido
                  </button>
                </div>
              </div>
              <div class="row d-flex justify-content-center align-items-center">
                <div class="col-md-12 text-center">
                  <button id="largura" class="btn btn-primary">
                    Ir para a ficha de {{ "Falluno" }}
                  </button>
                </div>
              </div>
              <div class="row d-flex justify-content-center align-items-center">
                <div class="col-md-12 text-center">
                  <button
                    id="largura"
                    class="btn btn-primary"
                    @click.prevent="new_insert()"
                  >
                    Registrar um novo Pedido
                  </button>
                </div>
              </div>
            </div>
          </div>
        </b-modal>
      </div>
    </div>
    <div v-if="modal_estabelecer" class="row">
      <div class="col-md-12">
        <estabelece
          :inicial_obj1="inicial_obj"
          :item_obj1="item_obj"
          :items_pre="items_pre"
          :modal_estabelecer="modal_estabelecer"
          @voltar_estabelece="voltar_estabelece"
          @setPut="setPut"
        />
      </div>
    </div>
    <div v-if="modal_faturar" class="row">
      <div class="col-md-12">
        <faturar
          :p_contas2 = "p_contas2"
          :inicial_obj1="inicial_obj"
          :item_obj1="item_obj"
          :items_pre="items_pre"
          :abr_fatura="modal_faturar"
          @faturando_fecha="faturando_fecha"
          @setPut="setPut"
        />
      </div>
    </div>
  </div>
</template>

<script>
import estabelece from "./estabelecer.vue";
import faturar from "./faturar.vue";
export default {
  name: "menu1",
  data() {
    return {
      menu_abre: null,
      modal_estabelecer: false,
      modal_faturar: false,
      //abr_fatura: false
      items_pre: [],
      item_obj: null,
      inicial_obj: null,
      p_contas2:[]
    };
  },
  components: {
    estabelece,
    faturar,
  },
  props: {
    //menu: { type: Boolean },
    // dusando esse de froma temprarai
    menu2_abre: { type: Boolean },
    item_objeto: { type: Object },
    inicial_objeto: { type: Object },
    items_push: { type: Array },
    p_contas1: { type: Array },
  },
  created() {
    this.inicial_obj = this.inicial_objeto;
    // os itens que vem da tela de insert;
    this.items_pre = this.items_push;
    this.item_obj = this.item_objeto;
    // caso de erro descomenta esse;
     this.menu_abre = this.menu2_abre;
   // this.menu_abre = this.menu2_abre
    this.p_contas2 = this.p_contas1
  },
  mounted() {},
  methods: {
    menu2_open(){
       this.$emit('menu2_open')
    },
    // serve para volatar para a parte de insert
    new_insert() {
      this.menu_abre = false;
      /// this.$emit('')
    },
    faturando_modal_abrir() {
      console.log("abrindo fatura")
      this.modal_faturar = true;
    },
    faturando_fecha() {
      this.modal_faturar = false;
    },
    modal_abrir_estabelece() {
      this.modal_estabelecer = true;
    },
    back() {
      this.$emit("back");
    },
    voltar_estabelece() {
      this.modal_estabelecer = false;
    },
    redirecionarParaOutraRota() {
      console.log("registrando");
      // Aqui você pode usar o método router.push para mudar a rota
      //this.$router.push('/cadastros/item'); // Altere 'outra-rota' para o caminho da rota desejada
    },
    setPut(objeto){
       console.log("estou no setput do Menu")
       console.log(objeto)
       this.$emit('doPut',objeto)
    }
  },
};
</script>

<style  scoped>
#largura {
  width: 400px;
  margin-bottom: 10px;
}
</style>>
