<script>
import Multiselect from "vue-multiselect";
import required from "vuelidate/lib/validators";
import { http } from "../../../helpers/easyindustriaapi/config";

export default {
  props: {
    oldpre_compra: { type: Object, required: true },
    listPrecompra: { type: Array, required: true },
    /// parentSelected: { type: Object, required: true }
    fornecedores: { type: Array },
    compradores: { type: Array },
    produtos_array: { type: Array },
  },
  components: {
    Multiselect,
  },
  data() {
    return {
      tipo_pd: null,
      select_comprador: null,
      loader: { get: false },
      titleBody: "Alterar Grupo",
      tabelas: {
        ttp_nome: null,
      },
      // items: [
      //   "Materia Prima (Rendimento em Produção)",
      //   "Produto Semi-Elaborado",
      //   "Materia Prima Comum",
      //   "Suprimento",
      //   "Produto Final",

      // ],
      items: ["pedido1", "pedido2", "pedido3"],
      order: {},
      dateselected_order: null,
      dateselected_arrival: null,
      hide: false,
      amount: null,
      tipo_selecionado: null,
      supplier_name_selected: null,
      supplier_code_selected: null,
      note: null,
      value: null,
      Feedstock: null, // materia prima
      value_order: null, // valor_pedido
      situation1: null,
      situation2: null,
      Buyer: null, // comprador
      Comments: null,
      pre_ped: {
        tipo_id: 2,
        fornecedor_id: 2,
        data: "2023/05/03",
        previsao_chegada: "2022/05/03",
        nnf: "nota44",
        valor: 12,
        situacao_id: 1,
        comprador_id: 2,
        obervacoes: "nada",
      },
      situacao2: null,
      situacao1: null,
      pre_pedido1: {},
      fornecedoresReceb: [],
      tipos_produtos2: [],
      pesquisa_codigo: null,
      vet_itens: [],
      tipos_produtos3: [
        {
          id: 1,
          nome: "materia Prima Comum",
        },
        {
          id: 2,
          nome: "materia  Prima não Comum",
        },
        {
          id: 3,
          nome: "Produto",
        },
      ],
      fields_itens: [
        {
          label: "Materia Prima",
          key: "mt_prima",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Lote",
          key: "lote",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Quantidade",
          key: "quantidade",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Preço",
          key: "preco",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Valor",
          key: "valor",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Ações",
          key: "excluir",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "",
          key: "add",
          tdClass: "text-center",
          thClass: "text-center",
        },
        // { label: "Ações", key: "excluir2", tdClass: 'text-center', thClass: 'text-center' },
      ],
      fields_itens1: [
        {
          label: "Materia Prima",
          key: "mt_prima",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Lote",
          key: "lote",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Quantidade",
          key: "quantidade",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Preço",
          key: "preco",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Valor",
          key: "valor",
          tdClass: "text-center",
          thClass: "text-center",
        },
        // {
        //   label: "Ações",
        //   key: "excluir",
        //   tdClass: "text-center",
        //   thClass: "text-center",
        // },
        {
          label: "Ações",
          key: "add",
          tdClass: "text-center",
          thClass: "text-center",
        },
        // { label: "Ações", key: "excluir2", tdClass: 'text-center', thClass: 'text-center' },
      ],
      pre_itens: [],
      select_fornecedor: null,
      pessoa_compradores: [],
      sub_total: null,
      alt_itens: false,
      items_pre_pedidos: [],
      produtos:[],
      select_produto:null,
      new_iten_bollean:false,
      vet_new_itens:[
      {
        prepedido_id: this.oldpre_compra.id,
        mt_prima: null,
        lote: null,
        quantidade: null,
        preco: null,
        valor: null,
      }
      ],
      atual_produto_id:null,
      atual_nome_produto:null
    };
  },
  validations: {
    tabelas: {
      ttp_nome: { required },
    },
  },
  created() {
    this.currentUser = JSON.parse(localStorage.getItem("user")); // para saber qual o usuario atual;
    this.currentEmpresa = JSON.parse(localStorage.getItem("currentEmpresa"));
    this.produtos= this.produtos_array
    this.pessoa_compradores = this.compradores;
    this.fornecedoresReceb = this.fornecedores;
    this.situacao1 = this.oldpre_compra.situacao_id;
    this.sub_total =
      this.oldpre_compra.itensprepedido[
        this.oldpre_compra.itensprepedido.length - 1
      ].total;
  },
  mounted() {
    // usado para preecnher a tebelas de intens;
    this.vet_itens = this.oldpre_compra.itensprepedido;
    this.setNewTitle();
    this.pre_pedido1 = this.oldpre_compra;
    this.teste = this.grupo;
    //console.log(this.pre_pedido1);
    this.note = this.oldpre_compra.nnf;
    this.value = this.oldpre_compra.valor;
    this.dateselected_order = this.oldpre_compra.data;
    this.dateselected_arrival = this.oldpre_compra.previsao_chegada;
    this.supplier_code_selected = this.oldpre_compra.fornecedor_id;
    this.supplier_name_selected = this.oldpre_compra.fornecedor_id + "- carlos"; // exemplo
    this.Comments = this.oldpre_compra.obervacoes;
    this.situation1 = this.oldpre_compra.situacao_id;
    this.preenche_comprador();
    this.preenche_tipo();
    this.preenche_fornecedor();
    this.calculo_sub_tot_inicial()
    //  this.get_item_prepedido();
  },
  methods: {
    calculo_sub_tot_inicial(){

      if(this.vet_new_itens.length > 0){
        let soma = 0
        this.vet_itens.forEach((item)=>{
           let valor = item.valor

           if(typeof valor === "string"){
            if(valor.includes('.') && valor.includes(',')){
              valor = parseFloat(valor.replace('.','').replace(',','.'))
            }else if(valor.includes('.') && (!valor.includes(','))){
              valor = parseFloat(valor.replace(',','.'))
            }
           }
           
           soma = soma + valor;
        })
        this.sub_total= this.formatterCurrBR(soma)
      }

    },
    
    calcular(preco1,index, objeto){
     // console.log("estou no metodo Calcular")
      // console.log(objeto)
       //console.log(index)

      let preco =  preco1
      let quantidade = objeto.quantidade
      let novo_total 
      
     /// console.log(preco)
     // console.log(quantidade)
      if(typeof  preco === "string" ){
        if(preco.includes(',') && preco.includes('.') ){
          preco = preco.replace(',','.').replace('.','')
        }else if(preco.includes(',') && (!preco.includes('.'))){
          preco = preco.replace(',','.')
        }
      }
      if(typeof  quantidade === "string" ){
        if(quantidade.includes(',') && quantidade.includes('.') ){
          quantidade = parseFloat(quantidade.replace(',','.').replace('.',''))

        }else if(quantidade.includes(',') && (!quantidade.includes('.'))){

          quantidade = parseFloat(quantidade.replace(',','.'))
        }else {
          quantidade = parseInt(quantidade)
        }
      }
      
      novo_total = parseFloat(preco) * quantidade
      //console.log(novo_total)
      this.vet_itens[index].valor = this.formatterCurrBR(novo_total)

      let soma =0
      this.vet_itens.forEach((item)=>{
        let num = item.valor
        

        if(typeof num == "string"){
          if(num.includes(',') && num.includes('.')){
            num = parseFloat(num.replace('.','').replace(',','.'))
          }else if(num.includes(',') && (!num.includes('.'))){
            num = parseFloat(num.replace(',','.'))
          }
          soma = soma + (num)
          //console.log(soma)
          this.sub_total=this.formatterCurrBR(soma)
        }else {
          soma = soma + (num)
          this.sub_total=this.formatterCurrBR(soma)
        }
      })
    },
    select_new_produto(objeto, linha){
         console.log(objeto)
         console.log(linha.index)
         this.vet_itens[linha.index].mt_prima = objeto.mt_prima
         console.log(this.vet_itens[linha.index].mt_prima)
         //this.select_produto = objeto.mt_prima
    },
    alterando_itens() {
      this.alt_itens = !this.alt_itens;
      this.new_iten_bollean = false
      

      this.vet_itens.forEach((objeto,index) => {
          /// console.log(index)
           this.preenche_valores_alterados(index , objeto)   
      });
    },

    // para prenhcer os valores do campos de mt_prima ,quando clicar em alterar

    preenche_valores_alterados(index,objeto){
      //console.log(index)
      //console.log(objeto.produto_id)
    
      if(typeof this.vet_itens[index].mt_prima == "string" ){
      let mt_prima_selecionada = this.produtos.find((p)=> p.id === objeto.produto_id )
      this.vet_itens[index].mt_prima =  mt_prima_selecionada 
     // console.log(mt_prima_selecionada.produto_nome)
      }

    },
    async get_item_prepedido() {
      try {
        let response = await http.get(
          "/pre-pedido?empresa_id=" + this.currentEmpresa.id
        );
        if (response.status === 200) {
          this.pre_itens = response.data;
          //  console.log(this.pre_itens)
          ///this.vet_itens = this.pre_itens.itensprepedido
          //console.log(this.vet_itens)
          setTimeout(() => {
            //this.offLoader();
          }, 2000); // tempo para carregar a tela;
        } else {
          this.pre_itens = []; // zera o array;
          this.makeToast("danger", "Erro ao carregar lista");
          // this.offLoader();
        }
      } catch (error) {
        // this.offLoader();
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error.response);
          }
        }
      }
    },
    preenche_comprador() {
      // pego o id do objeto
      let id = this.pre_pedido1.comprador_id;
      // faço a pesquisa pelo id do objeto
      // comparadno com o vetor fornecedores;
      // que na verdade é um vetor de pessoas cadastradas
      //console.log(this.pessoa_compradores);
      //console.log(this.pre_pedido1.comprador_id);
      const objetoEncontrado = this.pessoa_compradores.find(
        (objeto) => objeto.id === id
      );
      this.select_comprador = objetoEncontrado;
    },
    preenche_fornecedor() {
      // pego o id do objeto
      let id = this.pre_pedido1.fornecedor_id;
      // faço a pesquisa pelo id do objeto
      // comparadno com o vetor fornecedores;
      // que na verdade é um vetor de pessoas cadastradas
      const objetoEncontrado = this.fornecedoresReceb.find(
        (objeto) => objeto.id === id
      );
      this.select_fornecedor = objetoEncontrado;
    },
    preenche_tipo() {
      let id = this.pre_pedido1.tipo_id;
      const objetoEncontrado = this.tipos_produtos3.find(
        (objeto) => objeto.id === id
      );
      this.tipo_pd = objetoEncontrado;
    },
    // eslint-disable-next-line no-unused-vars
    async formSubmit(e) {
      this.submitted = true;
      // stop here if form is invalid
      await this.$v.$touch();
      this.validatesFields().then(() => {
        this.setPut();
      });
    },
    setNewTitle() {
      this.$emit("newTitle", this.titleBody);
    },
    coverter_float() {
      //typeof(this.pre_ped.valor)
      if (typeof this.pre_pedido1.valor == "string") {
        this.pre_ped2 = this.pre_pedido1;
        let stringValor = this.pre_ped2.valor;
        let valorFlutuante = parseFloat(
          stringValor.replace("R$", " ").replace(/\./g, "").replace(",", ".")
        );
        this.pre_pedido1.valor = valorFlutuante;
      }
    },
    converte_valor_vet_float(){
      let vetor = this.vet_itens

      vetor.forEach((item,index)=>{
          let preco = item.preco
          let valor = item.valor
           this.vet_itens[index].qtd_final = 0
          if(typeof preco == "string" ){
            if(preco.includes('.') && preco.includes(',') ){
              preco= parseFloat(preco.replace('.','').replace(',','.'))
              this.vet_itens[index].preco =preco
            }else if(preco.includes(',') && (!preco.includes('.'))){
              preco= parseFloat(preco.replace(',','.'))
              this.vet_itens[index].preco =preco
            }
          }else {
            this.vet_itens[index].preco =preco
          }
          if(typeof valor == "string" ){
            if(valor.includes('.') && valor.includes(',') ){
              valor= parseFloat(valor.replace('.','').replace(',','.'))
              this.vet_itens[index].valor =valor
            }else if(valor.includes(',') && (!valor.includes('.'))){
              valor= parseFloat(valor.replace(',','.'))
              this.vet_itens[index].valor =valor
            }
          }else {
            this.vet_itens[index].valor =valor
          }
      })
     
    },
    converte_data(){
       let vetor = this.vet_itens

       vetor.forEach((item,index)=>{
        let data = item.created_at
        let data_update = item.updated_at
       // console.log(data)

        if(typeof data == "string"){
          data = data.replace('.000000Z','')
         // console.log(data)
         this.vet_itens[index].created_at = data
        }
        // updated_at
        if(typeof data_update == "string"){
          data_update = data_update.replace('.000000Z','')
         // console.log(data_update)
         this.vet_itens[index].updated_at = data_update
        }
        
       })
    },
    converte_mt_prima(){


     let vetor = this.vet_itens

    
    
      vetor.forEach((item, index)=>{
      // pq quando eu seleciono um materia prima ela salva 
      // na variavel um objeto de produto e não somente o nome
      if( typeof item.mt_prima === 'object'){
        let nome_mt_prima = item.mt_prima.produto_nome
         this.vet_itens[index].mt_prima = nome_mt_prima
      }else {
        this.vet_itens[index].mt_prima = item.mt_prima
      }
     })

     
    },
    verifica_lote(){
         let vetor = this.vet_itens
        // console.log("estou em lote")
         vetor.forEach((item,index)=>{
             let lote = item.lote
             //console.log(lote)
             if(lote == '' || lote == undefined || lote == null){
              this.vet_itens[index].lote = '0'
             }
         })
    },

    convert_subtotal(){
     let sub_total = this.sub_total
     
      if(typeof sub_total === 'string'){
        if(sub_total.includes('.') &&  sub_total.includes(',')){
          sub_total = parseFloat(sub_total.replace('.','').replace(',','.'))
          this.sub_total = sub_total
        }else  if(sub_total.includes(',') &&  (!sub_total.includes('.'))){
          sub_total = parseFloat(sub_total.replace(',','.'))
          this.sub_total = sub_total
        }
      }else {
           this.sub_total = sub_total
      }
    },
    setPut() {
      this.coverter_float();
      this.converte_valor_vet_float()
      this.converte_data()
      this.converte_mt_prima()
      this.verifica_lote()
      this.convert_subtotal()
      //console.log("Gravou");
      this.pre_pedido1.total_pedidos = this.sub_total
      console.log(this.pre_pedido1.total_pedidos)
      this.pre_pedido1.vet= this.vet_itens
    
     // console.log(this.pre_pedido1.vet[0].lote)
      this.pre_pedido1.nnf = this.note;
      this.$emit("doPut", this.pre_pedido1);
    },
    formatInput_valor() {
      // Remova todos os caracteres não numéricos do valor de entrada
      let numericValue = this.pre_pedido1.valor.replace(/\D/g, "");
      // Formate o valor numérico para a moeda desejada (por exemplo, BRL)
      this.pre_pedido1.valor = parseFloat(numericValue / 100)
        .toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })
        .replace("R$", " ");
    },
    add_item() {
      this.vet_itens.push({
        mt_prima: null,
        lote: null,
        quantidade: null,
        preco: null,
        valor: null,
      });
    },
    formatInput_valor_vet(index) {
     // console.log(index)
      this.vet_itens[index].valor
      // Remova todos os caracteres não numéricos do valor de entrada

      let numericValue = this.vet_itens[index].valor.replace(/\D/g, "");
      // Formate o valor numérico para a moeda desejada (por exemplo, BRL)
      this.vet_itens[index].valor = parseFloat(numericValue / 100)
        .toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })
        .replace("R$", " ");
        //console.log(this.vet_itens[index].valor)
    },
    formatInput_preco_vet(index, objeto) {
      // console.log(index)
       //console.log(objeto)
     
      // chama função para fazer o novo calculo;
      
      this.vet_itens[index].preco
      
      // Remova todos os caracteres não numéricos do valor de entrada

      let numericValue = this.vet_itens[index].preco.replace(/\D/g, "");
      // Formate o valor numérico para a moeda desejada (por exemplo, BRL)
      this.vet_itens[index].preco = parseFloat(numericValue / 100)
        .toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })
        .replace("R$", " ");
        this.calcular(this.vet_itens[index].preco,index,objeto)
        
    },
    dlt_item(objeto1, index){
      this.vet_itens = this.vet_itens.filter(
        (objeto) => objeto.id !== objeto1.id
      );
      this.doDelete(objeto1)
      this.calcular(this.vet_itens[index].preco, index, objeto1)
    },
    makeToast(variant = null, message = "Error generic") {
      this.counter++;
      this.$bvToast.toast(message, {
        title: `Notificação`,
        toaster: "b-toaster-bottom-right",
        variant: variant,
        solid: true,
        appendToast: true,
      });
    },
    async doDelete(item_pre) {
      // console.log(pre_pedido);
     // this.onLoader();

    // console.log("entrei no delete")

      try {
        let response = await http.delete(
          "/item_pre_pedido/" +
            item_pre.id +
            "?empresa_id=" +
            this.currentEmpresa.id,
          item_pre
        );
        if (response) {
          if (response.status === 200) {
            //this.getData();
           /// this.offLoader();
            this.makeToast("warning", "Registro excluído");
            this.getData();
          }
        }
      } catch (error) {
        //this.offLoader();
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error);
          }
        }
      }
    },
    add_new_item(){
      this.new_iten_bollean= true
      
      this.vet_new_itens
    },
    incluir(objeto,index){
      console.log(index)
      this.converte_vet_new_valor()
      let item = {... objeto}
      item.produto_id = this.atual_produto_id
      item.quantidade_rendimento = this.vet_new_itens[0].quantidade
      item.mt_prima = this.atual_nome_produto
      
      this.doPost(item)
      //this.vet_itens.push(item)
      let index2 = (this.vet_itens.length-1)
      // chama novamente para recalculcar o subtotal
      this.calcular(item.preco,index2,item)
    },
    seleciona_produto_new(objeto){
      //console.log(objeto)
      this.atual_produto_id = objeto.id
      this.atual_nome_produto = objeto.produto_nome
    },
    formatInput_preco_new_vet(){

      let numericValue = this.vet_new_itens[0].preco.replace(/\D/g, "");
      // Formate o valor numérico para a moeda desejada (por exemplo, BRL)
      this.vet_new_itens[0].preco = parseFloat(numericValue / 100)
        .toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })
        .replace("R$", " ");
        let objeto = this.vet_new_itens[0]
        this.calcular_new_vet_valor(this.vet_new_itens[0].preco,0,objeto)
    },
    calcular_new_vet_valor(preco1,index,objeto){
      //console.log(objeto)
       //console.log(index)

      let preco =  preco1
      let quantidade = objeto.quantidade
      let novo_total 
      
     // console.log(preco)
     // console.log(quantidade)
      if(typeof  preco === "string" ){
        if(preco.includes(',') && preco.includes('.') ){
          preco = preco.replace(',','.').replace('.','')
        }else if(preco.includes(',') && (!preco.includes('.'))){
          preco = preco.replace(',','.')
        }
      }
      if(typeof  quantidade === "string" ){
        if(quantidade.includes(',') && quantidade.includes('.') ){
          quantidade = parseFloat(quantidade.replace(',','.').replace('.',''))

        }else if(quantidade.includes(',') && (!quantidade.includes('.'))){

          quantidade = parseFloat(quantidade.replace(',','.'))
        }else {
          quantidade = parseInt(quantidade)
        }
      }
      
      novo_total = parseFloat(preco) * quantidade
      //console.log(novo_total)
      this.vet_new_itens[index].valor = this.formatterCurrBR(novo_total)

      let soma =0
      this.vet_itens.forEach((item)=>{
        let num = item.valor
        

        if(typeof num == "string"){
          if(num.includes(',') && num.includes('.')){
            num = parseFloat(num.replace('.','').replace(',','.'))
          }else if(num.includes(',') && (!num.includes('.'))){
            num = parseFloat(num.replace(',','.'))
          }
          soma = soma + (num)
          //console.log(soma)
          this.sub_total=this.formatterCurrBR(soma)
        }else {
          soma = soma + (num)
          this.sub_total=this.formatterCurrBR(soma)
        }
      })
    },
    preenchendo_mt_denovo(){
      this.vet_itens.forEach((objeto,index) => {
          /// console.log(index)
           this.preenche_valores_alterados(index , objeto)   
      });
    },
    async getData() {
      

      try {
        let response = await http.get(
          "/item_pre_pedido?prepedido_id=" + this.oldpre_compra.id
        );

        if (response.status === 200) {
          this.vet_itens = response.data;
          console.log(this.vet_itens)   
          this.preenchendo_mt_denovo()
          this.calculo_sub_tot_inicial()
         // this.alterando_itens()    
        } else {
          this.vet_itens = []; // zera o array;
          this.makeToast("danger", "Erro ao carregar lista");
          this.offLoader();
        }
      } catch (error) {
       

        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error.response);
          }
        }
      }
    },
    async doPost(pre_pedido) {
      // id
      // DescricaoUn
      // un_codigo
      // empresa_id
    
      
      
      console.log(pre_pedido.valor)

      pre_pedido.empresa_id = this.currentEmpresa.id;
      pre_pedido.user_id = this.currentUser.id;

      //this.onLoader();

      try {
        let response = await http.post(
          "/item_pre_pedido?empresa_id=" + this.currentEmpresa.id,
          pre_pedido
        );
        if (response) {
         // this.offLoader();
          if (response.status === 200) {
             // console.log(response.data);
            //this.getData();
            //this.abri_menu_modal()
            this.inserindo = false;
            this.makeToast("success", "Registro incluído");
            this.getData()
          }
        }
      } catch (error) {
        //this.offLoader();
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error);
          }
        }
      }
    },
    converte_vet_new_valor(){
          let preco = this.vet_new_itens[0].preco
          let valor = this.vet_new_itens[0].valor

          if(typeof preco == "string" ){
            if(preco.includes('.') && preco.includes(',') ){
              preco= parseFloat(preco.replace('.','').replace(',','.'))
              this.vet_new_itens[0].preco =preco
            }else if(preco.includes(',') && (!preco.includes('.'))){
              preco= parseFloat(preco.replace(',','.'))
              this.vet_new_itens[0].preco =preco
            }
          }else {
            this.vet_new_itens[0].preco =preco
          }
          if(typeof valor == "string" ){
            if(valor.includes('.') && valor.includes(',') ){
              valor= parseFloat(valor.replace('.','').replace(',','.'))
              this.vet_new_itens[0].valor =valor
            }else if(valor.includes(',') && (!valor.includes('.'))){
              valor= parseFloat(valor.replace(',','.'))
              this.vet_new_itens[0].valor =valor
            }
          }
    }
  },
};
</script>
<template>
  <div class="color">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-3">
            <label for="tipo">Tipo:</label>
            <multiselect
              :options="tipos_produtos3"
              v-model="tipo_pd"
              label="nome"
            >
            </multiselect>
          </div>
          <div class="col-md-3">
            <label for="fornecedor">Fornecedor:</label>
            <multiselect
              v-model="select_fornecedor"
              :options="fornecedoresReceb"
              label="pes_apelido"
            >
            </multiselect>
          </div>
          <div class="col-md-3">
            <label for="data">Data do pedido:</label>
            <input
              v-model="pre_pedido1.data"
              type="date-time"
              class="form-control text-left"
            />
          </div>
          <div class="col-md-3">
            <label for="data">Data da Chegada:</label>
            <input
              v-model="pre_pedido1.previsao_chegada"
              type="date-time"
              class="form-control text-left"
            />
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-md-6">
            <label for="tipo">Nota Fiscal:</label>
            <input
              v-model="pre_pedido1.nnf"
              type="text"
              class="form-control text-left"
            />
          </div>
          <div class="col-md-6">
            <label for="fornecedor">Valor:</label>
            <input
              @input="formatInput_valor()"
              v-model="pre_pedido1.valor"
              type="text"
              class="form-control text-left"
            />
          </div>
        </div>
      </div>
      <!-- segundo card , que esta dentro da div geral-->
    </div>
    <div class="card mt-4">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-button block v-b-toggle.itens variant="light">Itens: </b-button>
      </b-card-header>
      <b-collapse id="itens" accordion="pedido-accordion" role="tabpanel">
        <div class="card-body">
          <div class="row d-flex justify-content-center align-items-center">
            <div class="col-md-12 text-center">
              <p class="fonte-size">
                <b-badge
                  data-name="Itens:"
                  class="field-status"
                  variant="success"
                  >{{ "Itens:" }}</b-badge
                >
              </p>
            </div>
          </div>
          <div
            class="row d-flex justify-content-center align-items-center"
            v-if="!alt_itens"
          >
            <div class="col-md-12 text-center">
              <b-table
                striped
                hover
                :items="vet_itens"
                responsive
                id="doc-itens"
                :fields="fields_itens"
              >
              <template #cell(mt_prima)="row">
                  <span class="text-right">{{
                         (row.item.mt_prima.produto_nome != null) ? row.item.mt_prima.produto_nome : row.item.mt_prima 
                        }}</span>
                </template>
              </b-table>
            </div>
          </div>
          <div class="row justify-content-center align-items-center" v-if="alt_itens">
            <div class="col-md-12 text-center">

              <div class="row d-flex justify-content-center align-items-center">
                <div class="col-md-12 text-center">
                  <h1>Alterando itens</h1>
                </div>
              </div>

              <b-table
                striped
                hover
                :items="vet_itens"
                responsive
                id="itens"
                :fields="fields_itens"
              >
                <!-- <template #cell(data_criacao)="row">
                        <span class="text-right">{{
                          formatDateTimeBR(row.item.data_criacao)
                        }}</span>
                      </template> -->
                <template #cell(valor)="row">
                  <!-- <span class="text-center">{{
                          formatterCurrBR(row.item.valor)
                        }}</span> -->
                  <input
                    type="text"
                    disabled
                    @input="formatInput_valor_vet(row.index)"
                    class="form-control text-left"
                    v-model="row.item.valor"
                  />
                </template>
                <template #cell(preco)="row">
                  <!-- <span class="text-right">{{
                          formatterCurrBR(row.item.preco)
                        }}</span> -->
                  <input
                    @input="formatInput_preco_vet(row.index, row.item)"
                    type="text"
                    class="form-control text-left"
                    v-model="row.item.preco"
                  />
                </template>
                <template #cell(quantidade)="row">
                  <!-- <span class="text-right">{{
                          formatterCurrBR(row.item.preco)
                        }}</span> -->
                  <input
                    @input="calcular(row.item.valor,row.index,row.item)"
                    type="text"
                    class="form-control text-left"
                    v-model="row.item.quantidade"
                  />
                </template>
                <template #cell(mt_prima)="row">
                  <!-- <span class="text-right">{{
                          formatterCurrBR(row.item.preco)
                        }}</span> -->
                  <!-- <input type="text" class="form-control text-left" v-model="row.item.mt_prima"> -->
                  <multiselect :options="produtos" v-model="row.item.mt_prima" @select="select_new_produto(row.item, row)"  label="produto_nome">

                  </multiselect>
                </template>
                <template #cell(lote)="row">
                  <!-- <span class="text-right">{{
                          formatterCurrBR(row.item.preco)
                        }}</span> -->
                  <input
                    type="text"
                    class="form-control text-left"
                    v-model="row.item.lote"
                  />
                </template>
                <template #cell(excluir)="row">
                  <button @click="dlt_item(row.item, row.index)" class="btn btn-danger">
                    Excluir
                  </button>
                </template>
                <!-- <template #cell(add)="row">
                  <button @click="add_item(row.item, row.index)" class="btn btn-danger">
                    +Add
                  </button>
                </template> -->
                
              </b-table>
            </div>
          </div>
          <div
            class="row d-flex justify-content-center align-items-center"
            v-if="!alt_itens"
          >
            <div class="col-md-12 text-center">
              <button class="btn btn-secondary" @click="alterando_itens()">
                alterar
              </button>
            </div>
          </div>
           
          <div class="row" v-if="new_iten_bollean">
            <div class="col-md-12">
               <!-- <input type="text" class="form-control text-left"> -->
               <b-table
                striped
                hover
                :items="vet_new_itens"
                responsive
                id="itens"
                :fields="fields_itens1"
              >
                <!-- <template #cell(data_criacao)="row">
                        <span class="text-right">{{
                          formatDateTimeBR(row.item.data_criacao)
                        }}</span>
                      </template> -->
                <template #cell(valor)="row">
                  <!-- <span class="text-center">{{
                          formatterCurrBR(row.item.valor)
                        }}</span> -->
                  <input
                    type="text"
                    disabled
                    class="form-control text-left"
                    v-model="row.item.valor"
                  />
                </template>
                <template #cell(preco)="row">
                  <!-- <span class="text-right">{{
                          formatterCurrBR(row.item.preco)
                        }}</span> -->
                  <input
                    type="text"
                    class="form-control text-left"
                    v-model="row.item.preco"
                    @input="formatInput_preco_new_vet()"
                  />
                </template>
                <template #cell(quantidade)="row">
                  <!-- <span class="text-right">{{
                          formatterCurrBR(row.item.preco)
                        }}</span> -->
                  <input
                    type="text"
                    class="form-control text-left"
                    v-model="row.item.quantidade"
                  />
                </template>
                <template #cell(mt_prima)="row">
                  <!-- <span class="text-right">{{
                          formatterCurrBR(row.item.preco)
                        }}</span> -->
                  <!-- <input type="text" class="form-control text-left" v-model="row.item.mt_prima"> -->
                  <multiselect :options="produtos" v-model="row.item.mt_prima" label="produto_nome" @select="seleciona_produto_new($event)">

                  </multiselect>
                </template>
                <template #cell(lote)="row">
                  <!-- <span class="text-right">{{
                          formatterCurrBR(row.item.preco)
                        }}</span> -->
                  <input
                    type="text"
                    class="form-control text-left"
                    v-model="row.item.lote"
                  />
                </template>
                <!-- <template #cell(excluir)="row">
                  <button @click="dlt_item(row.item, row.index)" class="btn btn-danger">
                    Excluir
                  </button>
                </template> -->
                <template #cell(add)="row">
                  <button @click="incluir(row.item, row.index)" class="btn btn-success">
                    Incluir
                  </button>
                </template>
                
              </b-table>
            </div>
          </div>
          <div
            class="row d-flex justify-content-center align-items-center mt-4"
            v-if="alt_itens"
          >
            <div class="col-md-12 text-center" >
              <button class="btn btn-info" @click="add_new_item()">
                {{"+ Add"}}
              </button>
            </div>
          </div>
          <div
            class="row d-flex justify-content-center align-items-center mt-4"
            v-if="alt_itens"
          >
            <div class="col-md-12 text-center">
              <button class="btn btn-secondary" @click="alterando_itens()">
                Salvar
              </button>
            </div>
          </div>
          

          <div class="row d-flex justify-content-right align-items-right">
            <div class="col-md-12 text-right">
              <Label>Subtotal: {{ sub_total }}</Label>
            </div>
          </div>
        </div>
      </b-collapse>
    </div>
    <!-- terceiro card -->
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
            <label for="situacao">Situação :</label>
            <!-- @change="outros_valores($event)" -->
            <b-form-checkbox v-model="situacao1" value="0" id="checkbox1">
              Considerar que os produtos já foram recebidos
            </b-form-checkbox>
            <b-form-checkbox v-model="situacao1" value="1" id="checkbox2">
              Comfirma que a compra já está confirmada e estabelecida
            </b-form-checkbox>
          </div>
          <div class="col-md-6">
            <label for="situacao">Comprador :</label>
            <multiselect
              v-model="select_comprador"
              :options="fornecedoresReceb"
              label="pes_fantasia"
            >
            </multiselect>
          </div>
        </div>
      </div>
    </div>
    <!-- quarto card -->
    <div class="card">
      <div class="card-body">
        <div class="row d-flex justify-content-center align-items-center">
          <div class="col-md-12 text-center">
            <button class="btn btn-secondary" @click="setPut()">Gravar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
 
<style  scoped>
.fonte-size {
  font-size: 30px;
}
</style>