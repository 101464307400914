<script>
/**
 * Widget Component
 */
export default {
//  props:['valor','pagamento'],
   props:{
    inicial_prePedido : {type : Object}
   },
   mounted(){
     console.log('estou  no widget')
     console.log(this.inicial_prePedido)
     this.setar_valores_inicia()
   },
   methods:{
      setar_valores_inicia(){
        if(this.inicial_prePedido.total_pedidos != null ){
          this.statData[2].value = this.inicial_prePedido.total_pedidos
        }else {
          this.statData[2].value = '0,00'
        }
        if(this.inicial_prePedido.data != null){
          this.statData[3].value = this.inicial_prePedido.data
        }else {
          this.statData[3].value = 'Sem data'
        }
        //this.statData[3].value = this.inicial_prePedido.data
        if(this.inicial_prePedido.objeto_fornecedor_id.pes_apelido != null){
          this.statData[1].value = this.inicial_prePedido.objeto_fornecedor_id.pes_apelido
        }else {
          this.statData[1].value = 'Sem Fornecedor'
        }
        
      }
   },
  data() {
    return {
      statData: [
        {
          title: "Pedido",
          icon: "ri-stack-line",
          value: "1",
          subvalue: ""
        },
        {
          title: "Fornecedor",
          icon: "ri-store-2-line",
          value: "Nutricau",
          subvalue: ""
        },
        {
          title: "Valor total dos Pedidos",
          icon: "ri-briefcase-4-line",
          value: "2,00",
          subvalue: ""
        },
        {
          title: "Data do Faturamento",
          icon: "ri-briefcase-4-line",
          value: "10/02/2023",
          subvalue: ""
        }
      ]
    };

  },
  watch: {
    // valor(novoValor) {
    //   this.statData[1].value = novoValor;
    //   this.statData[1].subvalue = novoValor;
    // },
    // pagamento(novoPagamento){
    //   this.statData[2].value = novoPagamento;
    //   this.statData[2].subvalue = novoPagamento;
    // },
    inicial_prePedido(objeto){
      this.statData[2].value = objeto.valor
    }
  }
};
// ola
</script>
<template>
  <div class="row">
    <div class="col-md-3" v-for="(data, index) in statData" :key="index">
      <div class="card">
        <div class="card-body">
          <div class="media">
            <div class="media-body overflow-hidden">
              <p class="text-truncate font-size-14 mb-2">{{data.title}}</p>
              <h4 class="mb-0">{{data.value}}</h4>
            </div>
            <div class="text-primary">
              <i :class="`${data.icon} font-size-24`"></i>
            </div>
          </div>
        </div>

        <div class="card-body border-top py-3">
          <div class="text-truncate">
            <span class="badge badge-soft-success font-size-11">
              <i class="mdi mdi-menu-up"></i>
              {{data.subvalue}}
            </span>
            <span class="text-muted ml-2">-Informação</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end row -->
</template>
<style scoped>


  
</style>