<template>
  <b-modal
    v-model="show_modal"
    size="xl"
    title="Visualizando Faturamento"
    hide-footer
    @hidden="back()"
  >
    <div class="row d-flex justify-content-center align-items-center mmt-4">
      <div class="col-md-12 text-center">
        <h2>
          <b-badge data-name="credito" class="field-status" variant="info">{{
            "Informações sobre Faturamento"
          }}</b-badge>
        </h2>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <visualizando :inicial_ver_fat="verfatObjeto" />
      </div>
    </div>
    <div class="row d-flex justify-content-center align-items-center mt-4">
      <div class="col-md-12 text-center">
          <button class="btn btn-secondary">Imprimir Relatorio</button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import visualizando from "./widget_ver_fat.vue";
export default {
  components: {
    visualizando,
  },
  props: {
    modal_ver_fat: { type: Boolean },
    item_obj1: { type: Object },
  },
  data() {
    return {
      data() {
        return {
          show_modal: false,
          verfatObjeto: {},
        };
      },
    };
  },
  created() {
    this.verfatObjeto = this.item_obj1;
    this.show_modal = this.modal_ver_fat;
  },
  mounted() {
    console.log(this.verfatObjeto);
  },
  methods: {
    back() {
      this.$emit("back");
    },
  },
};
</script>

<style>
</style>