<template>
  <b-modal
    v-model="abrir_modal_est"
    size="xl"
    title="Estabelecimento de Compra1"
    hide-footer
    @hidden="voltar_estabelece(), back()"
  >
    <div class="row">
      <div class="col-md-3">
        <label for="">Tipo:</label>
        <!-- <input
                    class="form-control text-left"
                    type="text"
                    placeholder="Gerado pelo sistema"
                    id="pedido_id"
                  /> -->
        <multiselect v-model="select_tipo" :options="tipos" label="nome">
        </multiselect>
      </div>
      <div class="col-md-3">
        <label for="">Fornecedor:</label>
        <multiselect
          v-model="select_pessoas"
          :options="fornecedores_pessoa"
          label="pes_apelido"
        >
        </multiselect>
      </div>
      <div class="col-md-2">
        <label for="">Data:</label>
        <input
          v-model="inicial.data"
          class="form-control text-left"
          type="date"
          placeholder="Gerado pelo sistema"
          id="pedido_id"
        />
      </div>
      <div class="col-md-2">
        <label for="">Nota Fiscal:</label>
        <input
          v-model="inicial.nota"
          class="form-control text-left"
          type="text"
          placeholder="Gerado pelo sistema"
          id="pedido_id"
        />
      </div>
      <div class="col-md-2">
        <label for="">Valor:</label>
        <input
          @input="formatInput_valor_inicial()"
          v-model="inicial.valor"
          class="form-control text-left"
          type="text"
          placeholder="Gerado pelo sistema"
          id="pedido_id"
        />
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-md-12">
        <Label>Items:</Label>
        <!-- Essa tabela tem que ter um v-for para receber os items 
                  do predidod , pois pode vim mais de um  -->
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <b-table-simple>
                  <b-thead id="b-head">
                    <b-tr>
                      <!-- Essa tabela tem que ter um v-for para receber os items 
                  do predidod , pois pode vim mais de um  -->
                      <b-th>&nbsp;</b-th>
                      <b-th>Quantidade Recebida</b-th>
                      <b-th
                        ><b-badge
                          data-name="Total"
                          class="field-status"
                          :variant="
                            bollean_qtd_final == true ? 'danger' : 'success'
                          "
                        >
                          {{ "*Quantidade Final" }}
                        </b-badge></b-th
                      >
                      <b-th>Preço</b-th>
                      <b-th>Valor</b-th>
                      <b-th>Aliquota</b-th>
                      <b-th>Cst</b-th>
                    </b-tr>
                  </b-thead>
                  <b-tbody>
                    <b-tr v-for="(item, index) in itens_d_insert" :key="index">
                      <!-- <b-td>{{ item.mt_prima }}</b-td> -->
                      <b-td>
                        <b-badge
                          data-name="Mt_prima"
                          class="field-status"
                          variant="info"
                        >
                          {{ item.mt_prima }}
                        </b-badge>
                      </b-td>
                      <b-td
                        ><input
                          placeholder="digite"
                          disabled
                          @input="formatInput_qtd_recebida(index)"
                          v-model="item.quantidade"
                          type="text"
                          name="qtd_receber"
                          class="form-control text-left"
                      /></b-td>
                      <b-td
                        ><input
                          placeholder="digite"
                          @input="formatInput_qtd_final(index), calcular(index)"
                          v-model="item.qtd_final"
                          type="text"
                          name="qtd_final"
                          class="form-control text-left"
                      /></b-td>
                      <b-td
                        ><input
                          placeholder="digite"
                          @input="formatInput_preco(index), calcular(index)"
                          v-model="item.preco"
                          type="text"
                          name="preco"
                          class="form-control text-left"
                          value="1.800"
                      /></b-td>
                      <b-td
                        ><input
                          placeholder="digite"
                          disabled
                          @input="formatInput_valor()"
                          v-model="item.valor"
                          type="text"
                          name="valor"
                          class="form-control text-left"
                          value="26%"
                      /></b-td>
                      <b-td
                        ><input
                          placeholder="digite"
                          @input="formatInput_aliquota()"
                          v-model="item.aliquota"
                          type="text"
                          name="aliquota"
                          class="form-control text-left"
                          value="26%"
                      /></b-td>
                      <b-td
                        ><input
                          placeholder="digite"
                          @input="formatInput_Cst()"
                          v-model="item.Cst"
                          type="text"
                          name="cst"
                          class="form-control text-left"
                          value="Cst"
                      /></b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row d-flex justify-content-center align-items-center">
      <div class="col-md-12 text-center">
        <label id="fonte" for=""
          >{{ tot_sub }} R$ :
          <b-badge data-name="Total" class="field-status" variant="success">{{
            total_valor
          }}</b-badge></label
        >
      </div>
    </div>
    <div class="row d-flex justify-content-center align-items-center">
      <div class="col-md-12 text-center">
        <!-- @change="intermed_veri(1)" -->
        <b-form-checkbox
          value="1"
          @change="outros_valores($event)"
          id="checkbox1"
        >
          Adicionar OUtros Valores
        </b-form-checkbox>
      </div>
    </div>
    <div
      v-if="valores_add"
      class="row d-flex justify-content-center align-items-center"
    >
      <div class="col-md-12 col-md-12 text-center">
        <!-- @change="intermed_veri(1)" -->
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12 text-right">
                <label for="" class="mr-2">Base de Calculo -></label>
                <input
                  @input="formatInput_bs_calculo()"
                  v-model="outro_valor.bs_calculo"
                  type="text"
                  class="estilo_input"
                  placeholder="Digite"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 text-right">
                <label for="" class="mr-2">Taxa % -> </label>
                <input
                  @input="formatInput_taxa()"
                  v-model="outro_valor.taxa"
                  type="text"
                  class="estilo_input"
                  placeholder="Digite"
                />
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-md-12 text-right">
                <label for="" class="mr-2"
                  >Redução(INSS) {{ "-> " }}{{ "-" }}{{ " R$" }}</label
                >
                <input
                  placeholder="Digite"
                  @input="formatInput_red_inss()"
                  v-model="outro_valor.red_inss"
                  type="text"
                  class="estilo_input"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 text-right">
                <label for="" class="mr-2"
                  >Frete {{ "-> " }}{{ "+" }}{{ " R$" }}</label
                >
                <input
                  placeholder="Digite"
                  @input="formatInput_frete()"
                  v-model="outro_valor.frete"
                  type="text"
                  class="estilo_input"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 text-right">
                <label for="" class="mr-2"
                  >Desconto {{ "-> " }}{{ "-" }}{{ " R$" }}</label
                >
                <input
                  placeholder="Digite"
                  @input="formatInput_desconto()"
                  v-model="outro_valor.desconto"
                  type="text"
                  class="estilo_input"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 text-right">
                <b-form-checkbox value="1" @change="funrural($event)">
                  Definir FunRural
                  <label for="" class="mr-2"
                    >{{ "-> " }}{{ sigla }}{{ " R$" }}</label
                  >
                  <input
                    placeholder="Digite"
                    @input="formatInput_funrural()"
                    v-model="outro_valor.funrural"
                    type="text"
                    class="estilo_input"
                    :disabled="test_fun_rural"
                  />
                </b-form-checkbox>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-md-12 text-right">
                <label id="fonte" for=""
                  >Total R$ :
                  <b-badge
                    data-name="Total"
                    class="field-status"
                    variant="success"
                    >{{ total_valor2 }}</b-badge
                  ></label
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="row d-flex justify-content-center align-items-center">
          <div class="col-md-12 text-left">
            <label for="">Observações:</label>
            <textarea
              v-model="observacoes"
              class="form-control text-left"
              name=""
              id=""
              cols="60"
              rows="10"
            >
            </textarea>
          </div>
        </div>
      </div>
      <div
        class="card-body d-flex justify-content-center align-items-center"
        id="top"
      >
        <div class="col-md-12 mt-12 text-center">
          <!-- Adicionado "text-center" aqui -->
          <button class="btn btn-secondary" id="butt" @click="setPut()">
            Estabelecer Compra
          </button>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { http } from "../../../helpers/easyindustriaapi/config";
import Multiselect from "vue-multiselect";
export default {
  name: "estabelecer",
  data() {
    return {
      bollean_qtd_final: true,
      observacoes: "",
      test_fun_rural: true,
      outro_valor: {
        funrural: null,
        desconto: null,
        frete: null,
        red_inss: null,
        taxa: null,
        bs_calculo: null,
      },
      sigla: "+",
      valores_add: false,
      tot_sub: "total",
      total_valor: null,
      total_valor2: null,
      item: {
        qtd_recebida: null,
        qtd_final: null,
        preco: 0,
        valor: 0,
        aliquota: null,
        Cst: null,
      },
      inicial: {
        tipo: null,
        fornecedor: null,
        data: null,
        nota: null,
        valor: null,
      },
      tipos: [
        {
          id: 1,
          nome: "materia Prima Comum",
        },
        {
          id: 2,
          nome: "materia  Prima não Comum",
        },
        {
          id: 3,
          nome: "Produto",
        },
      ],
      select_tipo: null,
      pessoas: [],
      select_pessoas: null,
      abrir_modal_est: null,
      fornecedores_pessoa: [],
      itens_d_insert: [],
      soma_final: 0,
      item_objeto: null,
      ini_obj: null,
    };
  },
  props: {
    modal_estabelecer: { type: Boolean },
    items_pre: { type: Array },
    item_obj1: { type: Object },
    inicial_obj1: { type: Object },
  },
  components: {
    Multiselect,
  },
  created() {
    this.ini_obj = this.item_obj1;
    this.itens_d_insert = this.item_obj1.itensprepedido;
    this.item_objeto = this.item_obj1;
    // this.itens_d_insert = this.items_pre;
    this.currentUser = JSON.parse(localStorage.getItem("user")); // para saber qual o usuario atual;
    this.currentEmpresa = JSON.parse(localStorage.getItem("currentEmpresa"));
    this.abrir_modal_est = this.modal_estabelecer;
    this.inicial.data = this.item_obj1.data;
    this.inicial.nota = this.item_obj1.nnf;
    this.inicial.valor = this.formatterCurrBR(this.item_obj1.valor);
    this.getFornecedores();
  },
  mounted() {
    //console.log(this.ini_obj);
    //  console.log(this.item_objeto)
    //this.total_inicial();
    this.getFornecedores();
    this.preenche_fornecedor();

    // console.log(this.item_obj1);
    // this.pessoas = this.fornecedores_pessoa
  },

  methods: {
    formatInput_valor_inicial() {
      // Remova todos os caracteres não numéricos do valor de entrada
      let numericValue = this.inicial.valor.replace(/\D/g, "");
      // Formate o valor numérico para a moeda desejada (por exemplo, BRL)
      this.inicial.valor = parseFloat(numericValue / 100)
        .toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })
        .replace("R$", " ");
    },
    convert_total() {
      let sub_total = this.total_valor2;
      this.ini_obj.total_pedidos;

      if (typeof sub_total === "string") {
        if (sub_total.includes(".") && sub_total.includes(",")) {
          sub_total = parseFloat(sub_total.replace(".", "").replace(",", "."));
          this.ini_obj.total_pedidos = sub_total;
        } else if (sub_total.includes(",") && !sub_total.includes(".")) {
          sub_total = parseFloat(sub_total.replace(",", "."));
          this.ini_obj.total_pedidos = sub_total;
        }
      } else {
        this.ini_obj.total_pedidos = sub_total;
      }
    },
    converte_valor_vet_float() {
      let vetor = this.itens_d_insert;

      vetor.forEach((item, index) => {
        let preco = item.preco;
        let valor = item.valor;
        let qtd_final = item.qtd_final;

        if (typeof preco == "string") {
          if (preco.includes(".") && preco.includes(",")) {
            preco = parseFloat(preco.replace(".", "").replace(",", "."));
            this.itens_d_insert[index].preco = preco;
          } else if (preco.includes(",") && !preco.includes(".")) {
            preco = parseFloat(preco.replace(",", "."));
            this.itens_d_insert[index].preco = preco;
          }
        } else {
          this.itens_d_insert[index].preco = preco;
        }
        if (typeof valor == "string") {
          if (valor.includes(".") && valor.includes(",")) {
            valor = parseFloat(valor.replace(".", "").replace(",", "."));
            this.itens_d_insert[index].valor = valor;
          } else if (valor.includes(",") && !valor.includes(".")) {
            valor = parseFloat(valor.replace(",", "."));
            this.itens_d_insert[index].valor = valor;
          }
        } else {
          this.itens_d_insert[index].valor = valor;
        }
        if (typeof qtd_final == "string") {
          if (qtd_final.includes(".") && qtd_final.includes(",")) {
            qtd_final = parseFloat(
              qtd_final.replace(".", "").replace(",", ".")
            );
            this.itens_d_insert[index].qtd_final = qtd_final;
          } else if (qtd_final.includes(",") && !qtd_final.includes(".")) {
            qtd_final = parseFloat(qtd_final.replace(",", "."));
            this.itens_d_insert[index].qtd_final = qtd_final;
          }
        } else {
          this.itens_d_insert[index].qtd_final = qtd_final;
        }
      });
    },
    setPut() {
      // total_valor2
      // pega o valor toal e coverte e joga na variavel total_pediods
      // do objejto
      this.convert_total();
      this.ini_obj.estabilizada = 1;
      this.converte_valor_vet_float();
      this.ini_obj.vet = this.itens_d_insert;
      console.log("estabelcendo 1");

      this.$emit("estabelecida", this.ini_obj);
    },
    back() {
      this.$emit("back");
    },
    total_inicial() {
      if (this.itens_d_insert.length > 1) {
        //console.log(this.itens_d_insert.length)
        if (this.itens_d_insert[1].valor != null) {
          // console.log(this.itens_d_insert.length);
          this.itens_d_insert.forEach((e) => {
            this.soma_final;
            let valor = e.valor.replace(/\./g, "").replace(",", ".");
            this.soma_final = this.soma_final + parseFloat(valor);
          });
        }
        // console.log(this.soma_final);
        this.total_valor = this.formatterCurrBR(this.soma_final);
        this.total_valor2 = this.formatterCurrBR(this.soma_final);

        //   if(this.itens_d_insert.valor != null){
        //   let valor = this.itens_d_insert[index].valor.replace(/\./g, "").replace(",", ".")
        //   console.log(valor)

        // }
      } else if (this.itens_d_insert.length == 1) {
        let valor = parseFloat(
          this.itens_d_insert[0].valor.replace(/\./g, "").replace(",", ".")
        );
        this.total_valor = this.formatterCurrBR(valor);
        this.total_valor2 = this.formatterCurrBR(valor);
      }
    },
    preenche_fornecedor() {
      console.log(this.fornecedores_pessoa);
      const objetoEncontrado = this.fornecedores_pessoa.find(
        (objeto) => objeto.id === this.ini_obj.fornecedor_id
      );

      this.select_pessoas = objetoEncontrado;

      const tipoencontrado = this.tipos.find(
        (t) => t.id == this.ini_obj.tipo_id
      );
      this.select_tipo = tipoencontrado;
    },
    async getFornecedores() {
      //  // this.onLoader();
      //  this.onLoader();
      try {
        let response = await http.get(
          "/fornecedor?empresa_id=" +
            this.currentEmpresa.id +
            "&fornecedor_id=" +
            -2
        );
        if (response.status === 200) {
          this.fornecedores_pessoa = response.data;
          //console.log(this.fornecedores_pessoa)
          //console.log(this.fornecedores_pessoa)
          this.preenche_fornecedor();
          setTimeout(() => {
            //this.offLoader();
          }, 2000); // tempo para carregar a tela;
        } else {
          this.fornecedores_pessoa = []; // zera o array;
          this.makeToast("danger", "Erro ao carregar lista");
          this.offLoader();
        }
      } catch (error) {
        this.offLoader();

        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error.response);
          }
        }
      }
    },
    voltar_estabelece() {
      this.$emit("voltar_estabelece");
    },
    outros_valores(num) {
      // console.log(num)
      // console.log(num)
      if (num == 1) {
        this.tot_sub = "Subtotal";
        this.valores_add = true;
      } else {
        this.tot_sub = "Total";
        this.valores_add = false;
      }
    },
    funrural(e) {
      if (e == 1) {
        this.test_fun_rural = false;
        this.sigla = "-";
      } else {
        this.outro_valor.funrural = null;
        this.test_fun_rural = true;
        this.sigla = "+";
      }
    },
    formatInput_bs_calculo() {
      // Remova todos os caracteres não numéricos do valor de entrada
      let numericValue = this.outro_valor.bs_calculo.replace(/\D/g, "");
      // Formate o valor numérico para a moeda desejada (por exemplo, BRL)
      this.outro_valor.bs_calculo = parseFloat(numericValue / 100)
        .toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })
        .replace("R$", " ");
    },
    formatInput_taxa() {
      // Remova todos os caracteres não numéricos do valor de entrada
      let numericValue = this.outro_valor.taxa.replace(/\D/g, "");
      // Formate o valor numérico para a moeda desejada (por exemplo, BRL)
      this.outro_valor.taxa = parseFloat(numericValue / 100)
        .toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })
        .replace("R$", " ");
    },
    formatInput_red_inss() {
      // Remova todos os caracteres não numéricos do valor de entrada
      let numericValue = this.outro_valor.red_inss.replace(/\D/g, "");
      // Formate o valor numérico para a moeda desejada (por exemplo, BRL)
      this.outro_valor.red_inss = parseFloat(numericValue / 100)
        .toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })
        .replace("R$", " ");
      this.reducao_Desconto();
    },
    formatInput_frete() {
      // Remova todos os caracteres não numéricos do valor de entrada
      let numericValue = this.outro_valor.frete.replace(/\D/g, "");
      // Formate o valor numérico para a moeda desejada (por exemplo, BRL)
      this.outro_valor.frete = parseFloat(numericValue / 100)
        .toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })
        .replace("R$", " ");
      this.reducao_Desconto();
    },
    formatInput_desconto() {
      // Remova todos os caracteres não numéricos do valor de entrada
      let numericValue = this.outro_valor.desconto.replace(/\D/g, "");
      // Formate o valor numérico para a moeda desejada (por exemplo, BRL)
      this.outro_valor.desconto = parseFloat(numericValue / 100)
        .toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })
        .replace("R$", " ");
      this.reducao_Desconto();
    },
    formatInput_funrural() {
      // Remova todos os caracteres não numéricos do valor de entrada
      let numericValue = this.outro_valor.funrural.replace(/\D/g, "");
      // Formate o valor numérico para a moeda desejada (por exemplo, BRL)
      this.outro_valor.funrural = parseFloat(numericValue / 100)
        .toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })
        .replace("R$", " ");
      this.reducao_Desconto();
      //this.reducao_funrural();
    },
    formatInput_qtd_recebida() {
      // Remova todos os caracteres não numéricos do valor de entrada
      let numericValue = this.item.qtd_recebida.replace(/\D/g, "");
      // Formate o valor numérico para a moeda desejada (por exemplo, BRL)
      this.item.qtd_recebida = parseFloat(numericValue / 100)
        .toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })
        .replace("R$", " ");
    },
    formatInput_qtd_final(index) {
      // Remova todos os caracteres não numéricos do valor de entrada

      //  qtd_final

      let numericValue = this.itens_d_insert[index].qtd_final.replace(
        /\D/g,
        ""
      );

      if (
        numericValue == null ||
        numericValue == undefined ||
        numericValue == "" ||
        isNaN(numericValue)
      ) {
        this.bollean_qtd_final = true;
      } else {
        this.bollean_qtd_final = false;
      }
      // Formate o valor numérico para a moeda desejada (por exemplo, BRL)
      this.itens_d_insert[index].qtd_final = parseFloat(numericValue / 100)
        .toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })
        .replace("R$", " ");
    },
    formatInput_preco(index) {
      // Remova todos os caracteres não numéricos do valor de entrada
      let numericValue = this.itens_d_insert[index].preco.replace(/\D/g, "");
      // Formate o valor numérico para a moeda desejada (por exemplo, BRL)
      this.itens_d_insert[index].preco = parseFloat(numericValue / 100)
        .toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })
        .replace("R$", " ");
    },
    formatInput_valor() {
      // Remova todos os caracteres não numéricos do valor de entrada
      let numericValue = this.item.valor.replace(/\D/g, "");
      // Formate o valor numérico para a moeda desejada (por exemplo, BRL)
      this.item.valor = parseFloat(numericValue / 100)
        .toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })
        .replace("R$", " ");
    },
    formatInput_aliquota() {
      // Remova todos os caracteres não numéricos do aliquota de entrada
      let numericValue = this.item.aliquota.replace(/\D/g, "");
      // Formate o aliquota numérico para a moeda desejada (por exemplo, BRL)
      this.item.aliquota = parseFloat(numericValue / 100)
        .toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })
        .replace("R$", " ");
    },
    formatInput_Cst() {
      // Remova todos os caracteres não numéricos do Cst de entrada
      let numericValue = this.item.Cst.replace(/\D/g, "");
      // Formate o Cst numérico para a moeda desejada (por exemplo, BRL)
      this.item.Cst = parseFloat(numericValue / 100)
        .toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })
        .replace("R$", " ");
    },
    calcular(index) {
      let itens = this.itens_d_insert[index];
      let num = itens.qtd_final;
      let num2 = itens.preco;
      console.log(num2);
      if (num != null) {
        var num1 = num.replace(/\./g, "").replace(",", ".");
      }
      if (num2 != 0 && typeof num2 == "string") {
        var preco1 = num2.replace(/\./g, "").replace(",", ".");
      } else {
        preco1 = num2;
      }
      if (!isNaN(preco1) && !isNaN(num1) && preco1 != "" && num1 != "") {
        let valor_final = parseFloat(num1) * parseFloat(preco1);
        // console.log(valor_final)
        // console.log(valor_final);
        this.item.valor = this.formatterCurrBR(valor_final);
        // coloquei esse posi pode ter mais de um item ,
        // então tenho que saber o indice do array,
        // para coclocar o valor correto
        this.itens_d_insert[index].valor = this.formatterCurrBR(valor_final);
        //console.log(this.itens_d_insert[index].valor)
        this.total_valor = this.formatterCurrBR(valor_final);
        this.total_valor2 = this.formatterCurrBR(valor_final);
        this.soma_final = 0;
        if (this.itens_d_insert.length > 1) {
          //console.log(this.itens_d_insert.length)
          if (this.itens_d_insert[index].valor != null) {
            // console.log(this.itens_d_insert.length);
            this.itens_d_insert.forEach((e) => {
              this.soma_final;
              let valor = e.valor.replace(/\./g, "").replace(",", ".");
              this.soma_final = this.soma_final + parseFloat(valor);
            });
          }
          // console.log(this.soma_final);
          this.total_valor = this.formatterCurrBR(this.soma_final);
          this.total_valor2 = this.formatterCurrBR(this.soma_final);
          //   if(this.itens_d_insert.valor != null){
          //   let valor = this.itens_d_insert[index].valor.replace(/\./g, "").replace(",", ".")
          //   console.log(valor)

          // }
        }
      }
    },
    reducao_Desconto() {
      // let total = this.total_valor2;
      let desconto = this.outro_valor.desconto;
      // total = this.total_valor.replace(/\./g, "").replace(",", ".");
      // desconto = this.outro_valor.desconto.replace(/\./g, "").replace(",", ".");
      // funrual = this.outro_valor.funrural;
      let funrual = this.outro_valor.funrural;
      let red_inss = this.outro_valor.red_inss;
      let frete = this.outro_valor.frete;
      //console.log(red_inss);
      if (funrual == null && red_inss == null && desconto != null) {
        // console.log("entrou no 1");

        if (this.outro_valor.frete != null) {
          let total = this.total_valor.replace(/\./g, "").replace(",", ".");
          let desconto1 = this.outro_valor.desconto
            .replace(/\./g, "")
            .replace(",", ".");
          let frete01 = this.outro_valor.frete
            .replace(/\./g, "")
            .replace(",", ".");
          let reducao =
            parseFloat(total) + parseFloat(frete01) - parseFloat(desconto1);
          this.total_valor2 = this.formatterCurrBR(reducao);
        } else {
          let total = this.total_valor.replace(/\./g, "").replace(",", ".");
          let desconto1 = this.outro_valor.desconto
            .replace(/\./g, "")
            .replace(",", ".");
          let reducao = parseFloat(total) - parseFloat(desconto1);
          this.total_valor2 = this.formatterCurrBR(reducao);
        }
      } else if (funrual != null && desconto != null && red_inss == null) {
        console.log("Entrou no 2");
        if (this.outro_valor.frete != null) {
          let total = this.total_valor.replace(/\./g, "").replace(",", ".");
          let desconto2 = this.outro_valor.desconto
            .replace(/\./g, "")
            .replace(",", ".");
          let funrural2 = this.outro_valor.funrural
            .replace(/\./g, "")
            .replace(",", ".");
          let frete002 = this.outro_valor.frete
            .replace(/\./g, "")
            .replace(",", ".");

          let reducao =
            parseFloat(total) +
            parseFloat(frete002) -
            (parseFloat(desconto2) + parseFloat(funrural2));
          // console.log(reducao);
          this.total_valor2 = this.formatterCurrBR(reducao);
        } else {
          let total = this.total_valor.replace(/\./g, "").replace(",", ".");
          let desconto2 = this.outro_valor.desconto
            .replace(/\./g, "")
            .replace(",", ".");
          let funrural2 = this.outro_valor.funrural
            .replace(/\./g, "")
            .replace(",", ".");
          let reducao =
            parseFloat(total) - (parseFloat(desconto2) + parseFloat(funrural2));
          // console.log(reducao);
          this.total_valor2 = this.formatterCurrBR(reducao);
        }
      } else if (funrual == null && desconto == null && red_inss != null) {
        //console.log("entrou no 3");

        if (this.outro_valor.frete != null) {
          let total = this.total_valor.replace(/\./g, "").replace(",", ".");
          let inss = this.outro_valor.red_inss
            .replace(/\./g, "")
            .replace(",", ".");
          let frete003 = this.outro_valor.frete
            .replace(/\./g, "")
            .replace(",", ".");
          let reducao =
            parseFloat(total) + parseFloat(frete003) - parseFloat(inss);
          this.total_valor2 = this.formatterCurrBR(reducao);
        } else {
          let total = this.total_valor.replace(/\./g, "").replace(",", ".");
          let inss = this.outro_valor.red_inss
            .replace(/\./g, "")
            .replace(",", ".");
          let reducao = parseFloat(total) - parseFloat(inss);
          // console.log(reducao);
          this.total_valor2 = this.formatterCurrBR(reducao);
        }
      } else if (funrual == null && desconto != null && red_inss != null) {
        //console.log("entrou no 4");

        if (this.outro_valor.frete != null) {
          let total = this.total_valor.replace(/\./g, "").replace(",", ".");
          let inss = this.outro_valor.red_inss
            .replace(/\./g, "")
            .replace(",", ".");
          let desconto4 = this.outro_valor.desconto
            .replace(/\./g, "")
            .replace(",", ".");
          let frete004 = this.outro_valor.frete
            .replace(/\./g, "")
            .replace(",", ".");
          let reducao =
            parseFloat(total) +
            parseFloat(frete004) -
            (parseFloat(inss) + parseFloat(desconto4));
          // console.log(reducao);
          this.total_valor2 = this.formatterCurrBR(reducao);
        } else {
          let total = this.total_valor.replace(/\./g, "").replace(",", ".");
          let inss = this.outro_valor.red_inss
            .replace(/\./g, "")
            .replace(",", ".");
          let desconto4 = this.outro_valor.desconto
            .replace(/\./g, "")
            .replace(",", ".");
          let reducao =
            parseFloat(total) - (parseFloat(inss) + parseFloat(desconto4));
          // console.log(reducao);
          this.total_valor2 = this.formatterCurrBR(reducao);
        }
      } else if (funrual != null && desconto != null && red_inss != null) {
        // console.log("entrou no 5");
        if (this.outro_valor.frete != null) {
          let total = this.total_valor.replace(/\./g, "").replace(",", ".");
          let inss = this.outro_valor.red_inss
            .replace(/\./g, "")
            .replace(",", ".");
          let desconto5 = this.outro_valor.desconto
            .replace(/\./g, "")
            .replace(",", ".");
          let rural = this.outro_valor.funrural
            .replace(/\./g, "")
            .replace(",", ".");
          let frete005 = this.outro_valor.frete
            .replace(/\./g, "")
            .replace(",", ".");

          let reducao =
            parseFloat(total) +
            parseFloat(frete005) -
            (parseFloat(inss) + parseFloat(desconto5) + parseFloat(rural));
          // console.log(reducao);
          this.total_valor2 = this.formatterCurrBR(reducao);
        } else {
          let total = this.total_valor.replace(/\./g, "").replace(",", ".");
          let inss = this.outro_valor.red_inss
            .replace(/\./g, "")
            .replace(",", ".");
          let desconto5 = this.outro_valor.desconto
            .replace(/\./g, "")
            .replace(",", ".");
          let rural = this.outro_valor.funrural
            .replace(/\./g, "")
            .replace(",", ".");
          let reducao =
            parseFloat(total) -
            (parseFloat(inss) + parseFloat(desconto5) + parseFloat(rural));
          // console.log(reducao);
          this.total_valor2 = this.formatterCurrBR(reducao);
        }
      } else if (funrual != null && desconto == null && red_inss == null) {
        //console.log("entrou no 6");
        if (this.outro_valor.frete != null) {
          let total = this.total_valor.replace(/\./g, "").replace(",", ".");
          let rural = this.outro_valor.funrural
            .replace(/\./g, "")
            .replace(",", ".");
          let frete006 = this.outro_valor.frete
            .replace(/\./g, "")
            .replace(",", ".");
          let reducao =
            parseFloat(total) + parseFloat(frete006) - parseFloat(rural);
          this.total_valor2 = this.formatterCurrBR(reducao);
        } else {
          let total = this.total_valor.replace(/\./g, "").replace(",", ".");
          let rural = this.outro_valor.funrural
            .replace(/\./g, "")
            .replace(",", ".");
          let reducao = parseFloat(total) - parseFloat(rural);
          // console.log(reducao);
          this.total_valor2 = this.formatterCurrBR(reducao);
        }
      } else if (
        funrual == null &&
        desconto == null &&
        red_inss == null &&
        frete != null
      ) {
        // console.log("entrou no 7");
        let total = this.total_valor.replace(/\./g, "").replace(",", ".");
        let frete1 = this.outro_valor.frete
          .replace(/\./g, "")
          .replace(",", ".");
        let soma = parseFloat(total) + parseFloat(frete1);
        // this.total_valor = this.formatterCurrBR(soma);
        this.total_valor2 = this.formatterCurrBR(soma);
      }
    },
  },
};
</script >

<style scoped>
#fonte {
  font-size: 20px;
}
.estilo_input {
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
</style>