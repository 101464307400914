<script>
//  criado a brach master
import appConfig from "@/app.config";
import { http } from "../../../helpers/easyindustriaapi/config";
import { authEasyMethods, notificationMethods } from "@/state/helpers";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import List from "./list";
import Insert from "./insert";
import Edit from "./edit";
import Menu2 from "./menu.vue";
import Multiselect from "vue-multiselect";
import consultManifeto from "./searchManifesto.vue"
import jsPDF from 'jspdf';
import 'jspdf-autotable';

export default {
  page: {
    title: "Pre Compra",
    meta: [{ name: "description", content: appConfig.description }],
  },

  // Menu2,
  components: { Menu2, Layout, PageHeader, Insert, List, Edit, Multiselect, consultManifeto },

  data() {
    return {
      data_inicial: null,
      data_final: null,
      select_fornecedor: null,
      select_tipos: null,
      n_pedido: null,
      ntf_pedido: null,
      currentpre_compra: {},
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Cadastro",
          href: "/",
          active: true,
        },
        {
          text: "Produto Item",
          href: "/Cadastro/item",
          active: true,
        },
      ],

      //Unidade: undefined,
      //pre_compra: [],
      grupos: undefined,
      pre_compra: [],
      pre_compra2: [],

      titleBody: "Pre Compra",
      editando: false,
      inserindo: false,
      showModal: false,
      tipos_produtct: [],
      fornecedores_pessoa: [],
      produtos2: [],
      p_contas: [],
      menu2_abre: false,
      currentPrePedido: null,
      currentItens: [],
      currentItem: null,
      compradores: [],
      hideSearches: false,
      opcoes: [
        {
          id: 1,
          nome: "Qualquer",
        },
        {
          id: 2,
          nome: "Pedido",
        },
        // perguntar isso:
        {
          id: 3,
          nome: "Aguardando Rendimetno",
        },
        {
          id: 4,
          nome: "Confirmada",
        },
        {
          id: 5,
          nome: "Estabelecida",
        },
        {
          id: 6,
          nome: "Pedido Não estabelecido ou Não Faturado",
        },
        {
          id: 7,
          nome: "Pedido Não estabelecido",
        },
        {
          id: 8,
          nome: "Pedido Não Faturado",
        },
        {
          id: 9,
          nome: "Pedido Faturado",
        },
        {
          id: 10,
          nome: "Pedido  Faturado e estabelecidos",
        },
      ],
      select_opcoes: null,
      array_fornecedores: [
        {
          id: 1,
          pes_apelido: "fornecedor 01",
        },
        {
          id: 2,
          pes_apelido: "fornecedor 01",
        },
      ],
      tios_produtos: [
        {
          id: 1,
          nome: "Qualquer",
        },
        {
          id: 2,
          nome: "Materia Prima(Redimento em Produção)",
        },
        {
          id: 3,
          nome: "Produto Semi-Elaborado",
        },
        {
          id: 4,
          nome: "Materia Prima Comum",
        },
        {
          id: 5,
          nome: "Suprimento",
        },
        {
          id: 6,
          nome: "Produto Final",
        },
      ],
      locais_estoques:[],
      show_manifesto:false,
      t_estoques:[]
    };
  },
  created() {
    this.currentUser = JSON.parse(localStorage.getItem("user")); // para saber qual o usuario atual;
    this.currentEmpresa = JSON.parse(localStorage.getItem("currentEmpresa")); // para saber a emrpessa atual;
    // storage esta no navegador em aplucation;descricaogrupo
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
    isHide() {
      return !this.inserindo && !this.editando && !this.show_manifesto;
    },
  },
  mounted() {
    this.getData();
   // this.PostNsu();
    this.getProducts_type();
    this.getFornecedores();
    this.getProdutos2();
    this.get_plano_de_contas();
    this.getCompradores();
    this.get_local_estoque();
    
   
  },
  methods: {
    ...authEasyMethods,
    ...notificationMethods,
    makeToast(variant = null, message = "Error generic") {
      this.counter++;
      this.$bvToast.toast(message, {
        title: `Notificação`,
        toaster: "b-toaster-bottom-right",
        variant: variant,
        solid: true,
        appendToast: true,
      });
    },
    
    manifesto(){
      this.show_manifesto = true;
      
    },
    async get_estoques_all() {
      try {
        let response = await http.get(
          "/estoque?empresa_id=" + this.currentEmpresa.id
        );
        if (response.status === 200) {
          this.t_estoques = response.data;
          //console.log(this.p_contas);
          this.gerar_pdf();
          setTimeout(() => {
            //this.offLoader();
          }, 800); // tempo para carregar a tela;
        } else {
          this.t_estoques = []; // zera o array;
          this.makeToast("danger", "Erro ao carregar lista");
          //this.offLoader();
        }
      } catch (error) {
        this.offLoader();
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error.response);
          }
        }
      }
    },
    gerar_pdf() {
            this.makeToast('sucess', 'Gerando PDF');
            let tamanho = this.t_estoques.length
            const doc = new jsPDF();
            const titulo = "Estoque";
            const header = ["Nome", "Quantidade Anterior", "Quantidade Atual"];
            const footer = ["Total Geral",`${tamanho}`, ""];
            // Adicionar o header e o foote
            doc.text(titulo, 80, 10,);
            // Array para armazenar as linhas da tabela
            const linhasDaTabela = [];
            // Preencher o array com linhas para cada objeto
            this.t_estoques.forEach(function (objeto) {
                const linha = [objeto.descricao, objeto.qtdeold, objeto.qtdenew];
                linhasDaTabela.push(linha);
            });
            doc.autoTable({
                head: [header],
                body: linhasDaTabela,
                foot: [footer],
            });
            // this.clientes.forEach(function (dado) {
            //     doc.autoTable({
            //         body: [dado],
            //     });
            // });
            // Salvar o documento
            doc.save("relatorio.pdf");
        },
    async PostNsu() {
      console.log('estou no NSU ')
      // id
      // DescricaoUn
      // un_codigo
      // empresa_id

      // NSU.empresa_id = this.currentEmpresa.id;
      // NSU.user_id = this.currentUser.id;
      let nsu = {
        cnpj :'28267948000127',
        uf:'PE'
      }
    
      console.log(nsu)
     // this.makeToast("success", "feito consulta NSU");
      //this.onLoader();
     
      //console.log(NSU)
      try {
        let response = await http.post(
          "/nfe/nsu",
          nsu
        );
        if (response) {
          // this.offLoader();
          if (response.status === 200) {
            // console.log(response.data);
            //this.getData();
            //this.abri_menu_modal()
            this.inserindo = false;
            this.makeToast("success", "feito consulta NSU");
            console.log(response.data)
          }
        }
      } catch (error) {
        //this.offLoader();
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error);
          }
        }
      }
    },
    async get_plano_de_contas() {
      try {
        let response = await http.get(
          "/plano_conta?empresa_id=" + this.currentEmpresa.id
        );
        if (response.status === 200) {
          this.p_contas = response.data;
          //console.log(this.p_contas);
          setTimeout(() => {
            //this.offLoader();
          }, 2000); // tempo para carregar a tela;
        } else {
          this.p_contas = []; // zera o array;
          this.makeToast("danger", "Erro ao carregar lista");
          //this.offLoader();
        }
      } catch (error) {
        this.offLoader();
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error.response);
          }
        }
      }
    },
    changeHomeTitle(newTitle) {
      this.titleBody = newTitle;
    },
    onLoader() {
      if (document.getElementById("preloader").style.display === "none") {
        document.getElementById("preloader").style.display = "block";
        document.getElementById("status").style.display = "block";
      }
    },
    offLoader() {
      if (document.getElementById("preloader").style.display === "block") {
        setTimeout(function () {
          document.getElementById("preloader").style.display = "none";
          document.getElementById("status").style.display = "none";
        }, 2500);
      }
    },
    async getProducts_type() {
      this.onLoader();

      try {
        let response = await http.get(
          "/tipoproduto?empresa_id=" + this.currentEmpresa.id
        );

        if (response.status === 200) {
          this.tipos_produtct = response.data;
          // console.log(this.tipos_produtct)
          //console.log(this.tipos_produtct)

          setTimeout(() => {
            this.offLoader();
          }, 2000); // tempo para carregar a tela;
        } else {
          this.tipos_produtct = []; // zera o array;
          this.makeToast("danger", "Erro ao carregar lista");
          this.offLoader();
        }
      } catch (error) {
        this.offLoader();

        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error.response);
          }
        }
      }
    },
    async getFornecedores() {
      this.onLoader();
      try {
        let response = await http.get(
          "/fornecedor?empresa_id=" +
            this.currentEmpresa.id +
            "&fornecedor_id=" +
            -2
        );
        if (response.status === 200) {
          this.fornecedores_pessoa = response.data;
          console.log("mostrnado pessoas fornecedores")
          console.log(this.fornecedores_pessoa)
          // console.log(this.fornecedores_pessoa)
          //console.log(this.fornecedores_pessoa)
          setTimeout(() => {
            this.offLoader();
          }, 800); // tempo para carregar a tela;
        } else {
          this.fornecedores_pessoa = []; // zera o array;
          this.makeToast("danger", "Erro ao carregar lista");
          this.offLoader();
        }
      } catch (error) {
        this.offLoader();

        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error.response);
          }
        }
      }
    },
    async getCompradores() {
      this.onLoader();

      // let response = await http.get(
      //     "/fornecedor?empresa_id=" + this.currentEmpresa.id + "&pes_fornecedor=" + -2
      //   );

      try {
        let response = await http.get(
          "/comprador?empresa_id=" +
            this.currentEmpresa.id +
            "&comprador_id=" +
            -1
        );
        if (response.status === 200) {
          this.compradores = response.data;
          // console.log(this.compradores)
          //console.log(this.compradores)
          setTimeout(() => {
            this.offLoader();
          }, 2000); // tempo para carregar a tela;
        } else {
          this.compradores = []; // zera o array;
          this.makeToast("danger", "Erro ao carregar lista");
          this.offLoader();
        }
      } catch (error) {
        this.offLoader();

        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error.response);
          }
        }
      }
    },
    async getProdutos2() {
      this.onLoader();

      try {
        let response = await http.get(
          "/produto?empresa_id=" + this.currentEmpresa.id
        );

        if (response.status === 200) {
          this.produtos2 = response.data;
          //console.log(this.produtos2)
          //console.log(this.produtos2)

          setTimeout(() => {
            this.offLoader();
          }, 2000); // tempo para carregar a tela;
        } else {
          this.produtos2 = []; // zera o array;
          this.makeToast("danger", "Erro ao carregar lista");
          this.offLoader();
        }
      } catch (error) {
        this.offLoader();

        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error.response);
          }
        }
      }
    },

    async getData() {
      this.onLoader();

      try {
        let response = await http.get(
          "/pre-pedido?empresa_id=" + this.currentEmpresa.id
        );

        if (response.status === 200) {
          this.pre_compra = response.data;
          //console.log(this.pre_compra)

          setTimeout(() => {
            this.offLoader();
          }, 1000); // tempo para carregar a tela;
        } else {
          this.pre_compra = []; // zera o array;
          this.makeToast("danger", "Erro ao carregar lista");
          this.offLoader();
        }
      } catch (error) {
        this.offLoader();

        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error.response);
          }
        }
      }
    },
    async get_local_estoque() {
      this.onLoader();

      try {
        let response = await http.get(
          "/localestoque?empresa_id=" + this.currentEmpresa.id
        );

        if (response.status === 200) {
          this.locais_estoques = response.data;
          console.log('locais estoques')
          console.log(this.locais_estoques)
          setTimeout(() => {
            this.offLoader();
          }, 1000); // tempo para carregar a tela;
        } else {
          this.locais_estoques = []; // zera o array;
          this.makeToast("danger", "Erro ao carregar lista");
          this.offLoader();
        }
      } catch (error) {
        this.offLoader();

        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error.response);
          }
        }
      }
    },
    setpre_compra(pre_compra) {
      this.currentpre_compra = pre_compra;
    },
    insert() {
      this.showModal = true;
      this.inserindo = !this.inserindo; // recebe true;
    },
    estabelecida(objeto) {
      this.doPut(objeto);
    },
    edit(oldpre_compra) {
      this.setpre_compra(oldpre_compra);
      this.editando = !this.editando;
    },

    async doPost(pre_pedido) {
      // id
      // DescricaoUn
      // un_codigo
      // empresa_id

      pre_pedido.empresa_id = this.currentEmpresa.id;
      pre_pedido.user_id = this.currentUser.id;

      //this.onLoader();
      console.log('estou mostrando os intens dentro do post')
      console.log(pre_pedido)
      try {
        let response = await http.post(
          "/pre-pedido?empresa_id=" + this.currentEmpresa.id,
          pre_pedido
        );
        if (response) {
          // this.offLoader();
          if (response.status === 200) {
            // console.log(response.data);
            //this.getData();
            //this.abri_menu_modal()
            this.inserindo = false;
            this.makeToast("success", "Registro incluído");
          }
        }
      } catch (error) {
        //this.offLoader();
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error);
          }
        }
      }
    },

    async doPut(pre_pedido) {
      // console.log(pre_pedido)
      // console.log("estou em put");
      // console.log(pre_pedido.vet);
      this.getData();
      this.onLoader();
      this.getData();

      try {
        let response = await http.put(
          "/pre-pedido/" +
            pre_pedido.id +
            "?empresa_id=" +
            this.currentEmpresa.id,
          pre_pedido
        );
        if (response) {
          this.offLoader();
          if (response.status === 200) {
            this.makeToast("success", "Registro alterado");
            this.getData();
            this.editando = false;
          }
        }
      } catch (error) {
        this.offLoader();
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error);
          }
        }
      }
    },
    async doDelete(pre_pedido) {
      // console.log(pre_pedido);
      this.onLoader();

      try {
        let response = await http.delete(
          "/pre-pedido/" +
            pre_pedido.id +
            "?empresa_id=" +
            this.currentEmpresa.id,
          pre_pedido
        );
        if (response) {
          if (response.status === 200) {
            this.getData();
            this.offLoader();
            this.makeToast("warning", "Registro excluído");
          }
        }
      } catch (error) {
        this.offLoader();
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error);
          }
        }
      }
    },
    close_modal() {
      // fecha o modal ao clicar;
      this.showModal = false;
    },
    back() {
      //this.getData();
      this.inserindo = false;
      this.editando = false;
      this.show_manifesto = false;
      this.titleBody = "Tipo pre_compra Preço";
      this.getData();
      this.menu2_abre = false;
    },
    // testando servdor
    menu2_open(pre_pedido, itens_push, item) {
      this.currentItens = itens_push;
      this.currentPrePedido = pre_pedido;
      this.currentItem = item;
      // console.log(this.currentPrePedido)
      this.back();
      this.menu2_abre = true;
      pre_pedido.vet = this.currentItens
      this.doPost(pre_pedido);
      //console.log(this.menu2_abre);
    },
    // abri_menu_modal() {
    //   this.$emit("abri_menu_modal");
    // },
    searchInputs() {
      this.hideSearches = !this.hideSearches;
    },
    async busca_n_pedidos() {
      //this.getData()
      this.makeToast("info", "Buscando Pedido por Número");

      try {
        let response = await http.get(
          "/pre-pedido/numero?id_desejado=" + this.n_pedido
        );

        if (response.status === 200) {
          this.pre_compra = response.data;
          // console.log(this.pre_compra);
          this.hideSearches = false;
        } else {
          this.pre_compra = []; // zera o array;
          this.makeToast("danger", "Erro ao carregar lista");
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error.response);
          }
        }
      }
    },
    async busca_fiscal_pedidos() {
      //this.getData()
      this.makeToast("info", "Buscando Pedido Número de  Nfe");
      try {
        let response = await http.get(
          "/pre-pedido/fiscal?nnf=" + this.ntf_pedido
        );

        if (response.status === 200) {
          this.pre_compra = response.data;
          //console.log(this.pre_compra);
          this.hideSearches = false;
          // tempo para carregar a tela;
        } else {
          this.pre_compra = []; // zera o array;
          this.makeToast("danger", "Erro ao carregar lista");
        }
      } catch (error) {
        //this.offLoader();

        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error.response);
          }
        }
      }
    },
    async busca_fiscal_num() {
      ///this.getData()
      try {
        let response = await http.get(
          "/pre-pedido/fiscal_num?nnf=" +
            this.ntf_pedido +
            "&pedido_id=" +
            this.n_pedido
        );
        if (response.status === 200) {
          this.pre_compra = response.data;
          // console.log(this.pre_compra);
          this.hideSearches = false;
        } else {
          this.pre_compra = []; // zera o array;
          this.makeToast("danger", "Erro ao carregar lista");
          //this.offLoader();
        }
      } catch (error) {
        //this.offLoader();

        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error.response);
          }
        }
      }
    },
    async busca_sit(num) {
      try {
        let response = await http.get("/pre-pedido/sit?sit_id=" + num);
        if (response.status === 200) {
          this.pre_compra = response.data;
          // console.log(this.pre_compra);
          this.hideSearches = false;
        } else {
          this.pre_compra = []; // zera o array;
          this.makeToast("danger", "Erro ao carregar lista");
        }
      } catch (error) {
        //   this.offLoader();

        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error.response);
          }
        }
      }
    },
    filtros() {
      let ntf = null;
      if (typeof this.ntf_pedido === "string") {
        ntf = parseInt(this.ntf_pedido);
      } else {
        ntf = "";
      }
      let num_p;
      if (typeof this.n_pedido == "string") {
        num_p = parseInt(this.n_pedido);
      } else {
        num_p = "";
      }
      console.log("num pedido : " + num_p);
      console.log("num nota fiscal : " + ntf);

      if (
        typeof num_p === "number" &&
        !isNaN(num_p) &&
        (typeof ntf !== "number" || isNaN(ntf))
      ) {
        console.log("entrou no 1");
        if (!isNaN(num_p)) {
          this.busca_n_pedidos();
        }
      } else if ((isNaN(num_p) || typeof num_p !== "number" ) && typeof ntf === "number" && !isNaN(ntf)) {
        console.log("entrou no 2");
        this.busca_fiscal_pedidos();
      } else if (
        typeof num_p === "number" &&
        !isNaN(num_p) &&
        typeof ntf === "number" &&
        !isNaN(ntf)
      ) {
        console.log("entrou no 3");
        this.busca_fiscal_num();
      }

      if (typeof this.select_opcoes === "object") {
        if (
          this.select_opcoes != null &&
          this.select_opcoes != undefined &&
          this.select_opcoes != ""
        ) {
          if (this.select_opcoes.id == 1) {
            this.getData();
          } else if (this.select_opcoes.id == 2) {
            this.busca_sit(1);
            this.makeToast("warning", "2");
          } else if (this.select_opcoes.id == 3) {
            this.busca_sit(1);
          } else if (this.select_opcoes.id == 4) {
            this.busca_sit(1);
          } else {
            this.getData();
          }
        }
      }

      if (
        typeof this.data_final === "string" &&
        typeof this.data_inicial === "string"
      ) {
        this.get_busca_entre_datas();
      }

      console.log("ola" +this.select_fornecedor + "onjeto fornecedor")

      if (typeof this.select_fornecedor === 'object' && this.select_fornecedor != null) {
        console.log("entrou no fornecedor");
        console.log("ola" +this.select_fornecedor + "onjeto fornecedor")
        if (
          this.select_fornecedor.id != null &&
          this.select_fornecedor.id != undefined &&
          this.select_fornecedor.id != ""
        ) {
          this.get_busca_por_fornecedor();
        } else {
          console.log("entrou no else do fornecedor");
          this.getData();
        }
      }

      if (typeof this.select_tipos === "object") {
        if (
          this.select_tipos != null &&
          this.select_tipos != undefined &&
          this.select_tipos != ""
        ) {
          if (this.select_tipos.id == 1) {
            this.makeToast('danger', 'Entrou no Tipos produtos')
            this.getData()
          }else {
            this.get_busca_por_tp_produto()
          }
        }
      }
    },

    async get_busca_entre_datas() {
      try {
        let response = await http.get(
          "/pre-pedido/data?data_inicial=" +
            this.data_inicial +
            "&data_final=" +
            this.data_final
        );
        if (response.status === 200) {
          this.pre_compra = response.data;
          //console.log(this.pre_compra);
          this.hideSearches = false;
          setTimeout(() => {
            this.offLoader();
          }, 100); // tempo para carregar a tela;
        } else {
          this.pre_compra = []; // zera o array;
          this.makeToast("danger", "Erro ao carregar lista");
          this.offLoader();
        }
      } catch (error) {
        this.offLoader();

        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error.response);
          }
        }
      }
    },

    async get_busca_por_fornecedor() {
      this.makeToast("info", "Buscando Por Fornecedor");
      try {
        let response = await http.get(
          "/pre-pedido/fornecedor?fornecedor_id=" +
            this.select_fornecedor.id +
            "&empresa_id=" +
            this.currentEmpresa.id
        );
        if (response.status === 200) {
          this.pre_compra = response.data;
         // console.log("estou buscando por fornecedor");
         // console.log(this.pre_compra);
          this.hideSearches = false;
          setTimeout(() => {
            this.offLoader();
          }, 100); // tempo para carregar a tela;
        } else {
          this.pre_compra = []; // zera o array;
          this.makeToast("danger", "Erro ao carregar lista");
          this.offLoader();
        }
      } catch (error) {
        this.offLoader();

        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error.response);
          }
        }
      }
    },

    async get_busca_por_tp_produto() {
      this.makeToast("info", "Buscando Por Tipo de Produto");
      try {
        let response = await http.get(
          "/pre-pedido/tp_produto?tipos_id=" +
            2 +
            "&empresa_id=" +
            this.currentEmpresa.id
        );
        if (response.status === 200) {
          this.pre_compra = response.data;
          //console.log("estou buscando por fornecedor");
          //console.log(this.pre_compra);
          this.hideSearches = false;
          setTimeout(() => {
            this.offLoader();
          }, 100); // tempo para carregar a tela;
        } else {
          this.pre_compra = []; // zera o array;
          this.makeToast("danger", "Erro ao carregar lista");
          this.offLoader();
        }
      } catch (error) {
        this.offLoader();
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error.response);
          }
        }
      }
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="titleBody" :items="items" />
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-12 col-md-4">
                <button
                  v-if="isHide"
                  class="btn btn-light"
                  @click="searchInputs()"
                >
                  <span
                    class="fa-sm fa"
                    :class="hideSearches ? 'fa-chevron-up' : 'fa-chevron-down'"
                  ></span>
                  Busca avançada
                </button>
              </div>
              <div class="col-sm-12 col-md-8 text-md-right">
                <button
                  @click="insert()"
                  v-if="isHide"
                  type="button"
                  class="btn btn-success"
                >
                  + Incluir produto Item
                </button>
                <button
                  @click="manifesto()"
                  v-if="isHide"
                  type="button"
                  class="btn btn-success ml-2"
                >
                  + Consultar Manifesto
                </button>
                <button
                  @click.prevent="get_estoques_all()"
                  v-if="isHide"
                  type="button"
                  class="btn btn-success ml-2"
                >
                  + gerar Rel Estoque
                </button>
                <button
                  @click="back()"
                  v-if="!isHide"
                  class="btn btn-secondary"
                >
                  Voltar
                </button>
              </div>
            </div>
          </div>
          <div class="card-body" v-if="hideSearches">
            <!-- Start Card -->
            <div class="card border border-primary">
              <div class="card-body">
                <h4 class="card-title">Filtros</h4>
                <p class="card-title-desc">Informações do Cliente</p>
                <form class="needs-validation" name="search">
                  <div class="row mt-4 mb-4">
                    <div class="col-md-4">
                      <label for="">{{ "Nº pedido" }}</label>
                      <input
                        v-model="n_pedido"
                        type="text"
                        class="form-control text-left"
                      />
                    </div>
                    <div class="col-md-4">
                      <label for="">{{ "Nota Fiscal" }}</label>
                      <input
                        v-model="ntf_pedido"
                        type="text"
                        class="form-control text-left"
                      />
                    </div>
                    <div class="col-md-4">
                      <label for="">{{ "Situação" }}</label>
                      <multiselect
                        v-model="select_opcoes"
                        :options="opcoes"
                        label="nome"
                        responsive
                      >
                      </multiselect>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <label for="">Data do Pedido</label>
                      <br />
                      <input
                        v-model="data_inicial"
                        type="datetime-local"
                        class="estilo_input"
                      />
                      <label for="" class="ml-4 mr-4">Até</label>
                      <input
                        v-model="data_final"
                        type="datetime-local"
                        class="estilo_input"
                      />
                    </div>
                  </div>
                  <div class="row mt-4 mb-4">
                    <div class="col-md-6">
                      <Label>Fornecedor</Label>
                      <multiselect
                        v-model="select_fornecedor"
                        :options="fornecedores_pessoa"
                        label="pes_apelido"
                      >
                      </multiselect>
                    </div>
                    <div class="col-md-6">
                      <Label>Tipos</Label>
                      <multiselect
                        v-model="select_tipos"
                        :options="tios_produtos"
                        label="nome"
                      >
                      </multiselect>
                    </div>
                  </div>
                  <button
                    class="btn btn-primary"
                    type="button"
                    @click="filtros()"
                  >
                    Buscar
                  </button>
                </form>
              </div>
            </div>
            <!-- End Card -->
          </div>
        </div>
        <div class="card">
          <div v-if="!isHide" class="card-body">
            <Edit
              v-if="editando"
              :empresa="currentEmpresa"
              :oldpre_compra="currentpre_compra"
              :listPrecompra="pre_compra"
              :fornecedores="fornecedores_pessoa"
              :compradores="compradores"
              @newTitle="changeHomeTitle"
              @doPut="doPut"
              :produtos_array="produtos2"
            />
            <Insert
              v-if="inserindo"
              @menu2_open="menu2_open"
              :p_contas="p_contas"
              :listGrupos="pre_compra"
              :listGrupos2="pre_compra"
              :currentEmpresa="currentEmpresa"
              @newTitle="changeHomeTitle"
              @doPost="doPost"
              @back="back"
              :showModal="showModal"
              :tipos_produtos="tipos_produtct"
              :fornecedores="fornecedores_pessoa"
              :produtos2="produtos2"
              :compradores="compradores"
              :locais_estoques="locais_estoques"
            />
            <consultManifeto v-if="show_manifesto" :show_manifesto="show_manifesto" @back="back"/>
          </div>
          <!--  tenta novamente dessa forma  -->
          <div class="card-body" v-if="menu2_abre">
            <Menu2
              :p_contas1="p_contas"
              :inicial_objeto="currentPrePedido"
              :items_push="currentItens"
              :item_objeto="currentItem"
              @back="back"
              :menu2_abre="menu2_abre"
              @menu2_open="menu2_open"
              @doPut="doPut"
            />
          </div>
          <!-- <b-modal
              v-model="showModal"
              id="modal-xl"
              size="xl"
              title="Tipo de Tabela de preços"
              hide-footer
            >
              <div class="form-row form-group" role="group">
                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <label for="tipo_sigla_id" class="col-form-label">Nome</label>
                    <div class="col">
                      <input
                        v-model="pre_compra.ttp_nome"
                        class="form-control text-leftt"
                        type="text"
                        placeholder="digite a Sigla"
                        id="sigla"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 mt-10">
                <button class="btn btn-light" @click="close_modal">Gravar</button>
              </div>
            </b-modal> -->

          <!-- Retirei isso ->  :listpre_compra="pre_compra" , para lista é so colocar de volta -->
          <List
            :listPreCompra="pre_compra"
            :currentEmpresa="currentEmpresa"
            :hide="isHide"
            :plano_contas="p_contas"
            :fornecedores_pessoa="fornecedores_pessoa"
            @edit="edit"
            @newTitle="changeHomeTitle"
            @setpre_compra="setpre_compra"
            @doDelete="doDelete"
            @estabelecida="estabelecida"
            @doPut="doPut"
          />
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.estilo_input {
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  width: 46%;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
</style>