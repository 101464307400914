<template>
  <b-modal
    v-model="faturando"
    size="xl"
    title="Faturamento"
    hide-footer
    @hidden="faturando_fecha(), back()"
  >
    <div class="row">
      <div class="col-md-12">
        <widget :inicial_prePedido="inicial_prePedido" />
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <label for="">Saldo:</label>
        <div class="card">
          <div class="card-body">
            <div>
              <div class="row">
                <div
                  class="col-md-3"
                  v-for="(saldo, index) in saldos"
                  :key="(index = saldo.id)"
                >
                  <div class="card">
                    <b-form-checkbox
                      @change="novo_total(saldo)"
                      :value="index"
                      v-model="select_saldo"
                    >
                      <b-badge
                        data-name="Total"
                        class="field-status"
                        variant="success"
                        >{{ saldo.saldo }}</b-badge
                      >
                      :: pgto :
                      <b-badge
                        data-name="Total"
                        class="field-status"
                        variant="danger"
                        >{{ saldo.id }}</b-badge
                      >
                    </b-form-checkbox>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <label for="">Comprador:</label>
        <multiselect
          v-model="select_comprador"
          :options="pessoas"
          label="pes_fantasia"
        >
        </multiselect>
      </div>
      <div class="col-md-6">
        <label for="">Plano de Contas :</label>
        <multiselect
          v-model="select_plano_contas"
          :options="p_contas"
          label="descricao"
        >
        </multiselect>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <label for="">Condições de Pagamento:</label>
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <b-form-checkbox value="1" id="checkbox5">
                  Com entrada -
                  <Label>Dividir em:</Label>
                  <input
                    v-model="gerar.dividir"
                    type="number"
                    min="1"
                    class="estilo_input mr-4 ml-4"
                  />

                  <Label>com o prazo de: </Label>
                  <input
                    v-model="gerar.prazo_dias"
                    type="number"
                    min="1"
                    class="estilo_input mr-4 ml-4"
                  />
                  {{ "dias" }}
                  <button
                    class="btn btn-secondary ml-4"
                    @click="gerar_parcelas()"
                  >
                    Gerar Parcelas
                  </button>
                </b-form-checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <b-table
          striped
          hover
          :items="fatura_vet"
          responsive
          id="doc-itens"
          :fields="fields_fatura"
        >
          <template #cell(tp_pagamento)="row">
            {{
              row.item.tp_pagamento === 1
                ? "Dinheiro"
                : row.item.tp_pagamento === 2
                ? "Duplicata"
                : row.item.tp_pagamento === 3
                ? "Cheque"
                : row.item.tp_pagamento === 4
                ? "Cartão de Credito"
                : row.item.tp_pagamento === 5
                ? "Cartão de Debito"
                : row.item.tp_pagamento === 6
                ? "Cupom Devolução"
                : row.item.tp_pagamento === 7
                ? "Transferencia"
                : row.item.tp_pagamento === 8
                ? "Vale Alimentação"
                : row.item.tp_pagamento === 9
                ? "Vale Refeição"
                : row.item.tp_pagamento === 10
                ? "Vale Presente"
                : row.item.tp_pagamento === 11
                ? "Vale Combustivel"
                : row.item.tp_pagamento === 12
                ? "Sem Pagamento"
                : "Outros"
            }}
          </template>
          <!-- <template #cell(valor)="row">
            <span class="text-right">{{
              formatterCurrBR(row.item.valor)
            }}</span>
          </template> -->
          <template #cell(excluir)="row">
            <button @click="Delete_fatura(row.item)" class="btn btn-danger">
              Excluir
            </button>
          </template>
          <template #cell(vencimento)="row">
            <!-- <span>{{row.item.vencimento}}</span> -->
            <input
              v-model="row.item.vencimento"
              type="date"
              class="estilo_input1"
              @change="select_vencimento(row.item, row.index)"
            />
          </template>
          <template #cell(nnf)="row">
            <b-form-checkbox
              :value="row.index"
              @change="selec_nf(row.item, row.index, 1)"
            >
            </b-form-checkbox>
          </template>
          <template #cell(esp_pag)="row">
            <multiselect
              v-model="row.item.esp_pag"
              class="larg_mult"
              :options="pagamentos"
              label="tiponome"
              @select="select_tp_pagamento(row.item, row.index)"
            ></multiselect>
          </template>
          <template #cell(conta)="row">
            <multiselect
              v-model="row.item.conta"
              class="larg_mult"
              :options="p_contas"
              label="descricao"
              @select="select_conta(row.item, row.index)"
            ></multiselect>
            <!-- <input type="text" class="estilo_input1" @click="select_conta(row.item)" > -->
          </template>
          <template #cell(n_doc)="row">
            <input
              v-model="row.item.n_doc"
              type="text"
              class="estilo_input1"
              @input="select_doc(row.item, row.index)"
            />
          </template>
          <template #cell(desc)="row">
            <input
              v-model="row.item.desc"
              type="text"
              class="estilo_input1"
              @input="select_desc(row.item, row.index)"
            />
          </template>
          <template #cell(valor)="row">
            <input
              v-model="row.item.valor"
              type="text"
              class="estilo_input1"
              @input="formatInput_vlr_fatura(row.index)"
            />
          </template>
          <!-- <template #cell(excluir2)="row">
              <multiselect v-model="nada" @select="fat(row.item)" label="tiponome" :options="pagamentos_tp"></multiselect>
            </template> -->
          <!-- <template #cell(tipo_pagamento_id)="row">
              {{ (row.item.tipo_pagamento_id === 1)  ? 'Dinheiro' 
              :  (row.item.tipo_pagamento_id === 2)  ? 'Duplicata' 
              :  (row.item.tipo_pagamento_id === 3)  ? 'Cheque' 
              :  (row.item.tipo_pagamento_id === 4)  ? 'Cartão de Credito' 
              :  (row.item.tipo_pagamento_id === 5)  ? 'Cartão de Debito' 
              :  (row.item.tipo_pagamento_id === 6)  ? 'Cupom Devolução' 
              :  (row.item.tipo_pagamento_id === 7)  ? 'Transferencia' 
              :  (row.item.tipo_pagamento_id === 8)  ? 'Vale Alimentação' 
              :  (row.item.tipo_pagamento_id === 9)  ? 'Vale Refeição' 
              :  (row.item.tipo_pagamento_id === 10) ? 'Vale Presente' 
              :  (row.item.tipo_pagamento_id === 11) ? 'Vale Combustivel' 
              :  (row.item.tipo_pagamento_id === 12) ? 'Sem Pagamento' : 'Outros' 
                }}
            </template> -->
        </b-table>
        <hr />
      </div>
    </div>
    <div class="row d-flex justify-content-right align-items-right">
      <div class="col-md-12 text-right">
        <div class="cerd">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12 mb-2">
                <label for="">Total -></label>
                <input
                  v-model="total2"
                  id="margin"
                  class="estilo_input"
                  type="text"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <b-form-checkbox value="3">
                  Ajustando Valores Automaticamente
                </b-form-checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <label for="">Observações:</label>
            <textarea
              name=""
              id=""
              cols="30"
              rows="10"
              class="form-control"
            ></textarea>
          </div>
        </div>
      </div>
    </div>
    <div class="row d-flex justify-content-center align-items-center">
      <div class="col-md-12 text-center">
        <!-- setPut(),  -->
        <button class="btn btn-secondary" @click.prevent="setPut()">
          Faturar
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { http } from "../../../helpers/easyindustriaapi/config";
import widget from "./widget.vue";
import Multiselect from "vue-multiselect";
export default {
  name: "faturar",
  props: {
    abr_fatura: { type: Boolean },
    items_pre: { type: Array },
    p_contas2: { type: Array },
    item_obj1: { type: Object },
    inicial_obj1: { type: Object },
  },
  //    criando o compoente fatura para tela de pre-ppedido
  components: {
    widget,
    Multiselect,
  },
  data() {
    return {
      select_saldo: null,
      faturando: null,
      tp_contas: [],
      tp_pagamentos: [],
      saldos: [],
      planos: [
        {
          id: 1,
          descricao: "plano 1",
        },
        {
          id: 2,
          descricao: "plano 2",
        },
        {
          id: 3,
          descricao: "plano 3",
        },
      ],
      p_contas: [],
      fatura: {},
      pessoas: [],
      inicial_prePedido: null,
      select_comprador: null,
      fatura_vet: [],
      fatura_parcela: {
        vencimento: "10-02-2023",
      },
      fields_fatura: [
        // {
        //   label: "id_pag",
        //   key: "id_pag",
        //   tdClass: "text-center",
        //   thClass: "text-center",
        // },
        {
          label: "Vencimento",
          key: "vencimento",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Esp Pagamento",
          key: "esp_pag",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Conta",
          key: "conta",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "DF",
          key: "nnf",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "N doc",
          key: "n_doc",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Descrição",
          key: "desc",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Valor",
          key: "valor",
          tdClass: "text-center",
          thClass: "text-center",
        },
        // { label: "Criado", key: "data_criacao", tdClass: 'text-right', thClass: 'text-center' },
        {
          label: "Ações",
          key: "excluir",
          tdClass: "text-center",
          thClass: "text-center",
        },
        // { label: "Ações", key: "excluir2", tdClass: 'text-center', thClass: 'text-center' },
      ],
      pagamentos: [],
      gerar: {
        prazo_dias: 30,
        dividir: 1,
      },
      total2: 0,
      select_plano_contas: null,
      ultimo_id: null,
      pre_pedidos: [],
      new_vet_pre_itens: [],
    };
  },
  created() {
    this.inicial_prePedido = this.inicial_obj1;
    this.total2 = this.inicial_prePedido.total_pedidos;
    this.faturando = this.abr_fatura;
    this.currentUser = JSON.parse(localStorage.getItem("user")); // para saber qual o usuario atual;
    this.currentEmpresa = JSON.parse(localStorage.getItem("currentEmpresa"));
    this.p_contas = this.p_contas2;
    //this.get_plano_de_contas();
  },
  mounted() {
    // console.log(this.inicial_prePedido)
    /// this.get_plano_de_contas();
    this.get_pesssoas();
    this.get_pagamentos();
    this.select_comprador = this.inicial_obj1.objeto_comprador_id;
    this.get_ultimo_prepedido();
    this.get_pagamentos_fornecedores();
    console.log(this.item_obj1);
    console.log(this.inicial_obj1);
  },
  methods: {
    formatInput_vlr_fatura(index) {
      let numericValue = this.fatura_vet[index].valor.replace(/\D/g, "");
      // Formate o valor numérico para a moeda desejada (por exemplo, BRL)
      this.fatura_vet[index].valor = parseFloat(numericValue / 100)
        .toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })
        .replace("R$", " ");
    },
    select_desc(objeto, index) {
      this.fatura_vet[index].desc = objeto.desc;
      console.log(this.fatura_vet[index].desc);
    },
    select_doc(objeto, index) {
      this.fatura_vet[index].n_doc = objeto.n_doc;
      console.log(this.fatura_vet[index].n_doc);
    },
    selec_nf(objeto, index, valor) {
      console.log(valor);
      this.fatura_vet[index].nnf = valor;
      console.log(this.fatura_vet[index]);
    },
    enviar_parcelas() {
      console.log("stou aqui no enviar parelas ");
      console.log(this.inicial_prePedido);
      console.log(this.item_obj1);
      if (this.inicial_prePedido.faturado == 0) {
        this.converte_valor_vet_fatura();
        let parcela = {};
        parcela.vet = this.fatura_vet;
        parcela.pre_pedido_id = this.ultimo_id;

        this.conta_a_pagar();
        this.fatura_vet.forEach((item) => {
          //console.log(item)
          this.post_parcelas(item);
        });
      }
    },
    async post_conta(conta) {
      let id_pre_pedido = this.inicial_prePedido.id;
      try {
        let response = await http.post(
          "/pagas?empresa_id=" +
            this.currentEmpresa.id +
            "&pre_pedido_id=" +
            id_pre_pedido,
          conta
        );
        if (response) {
          // this.offLoader();
          if (response.status === 200) {
            // console.log(response.data);
            //this.getData();
            //this.abri_menu_modal()
            this.inserindo = false;
            this.makeToast("success", "Registro incluído de contas a pagar");
          }
        }
      } catch (error) {
        //this.offLoader();
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error);
          }
        }
      }
    },
    async post_parcelas(parcela) {
      let id_pre_pedido = this.inicial_prePedido.id;
      try {
        // let response = await http.get(
        //   "/fornecedor?empresa_id=" +
        //     this.currentEmpresa.id +
        //     "&fornecedor_id=" +
        //     -2
        // );
        let response = await http.post(
          "/parcela?empresa_id=" +
            this.currentEmpresa.id +
            "&pre_pedido_id=" +
            id_pre_pedido,
          parcela
        );
        if (response) {
          // this.offLoader();
          if (response.status === 200) {
            // console.log(response.data);
            //this.getData();
            //this.abri_menu_modal()
            this.inserindo = false;
            // this.makeToast("success", "Registro incluído");
          }
        }
      } catch (error) {
        //this.offLoader();
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error);
          }
        }
      }
    },
    converte_valor_vet_fatura() {
      let vet = this.fatura_vet;

      vet.forEach((item, index) => {
        let valor = item.valor;

        if (typeof valor === "string") {
          if (valor.includes(".") && valor.includes(",")) {
            valor = parseFloat(valor.replace(".", "").replace(",", "."));
            this.fatura_vet[index].valor = valor;
          } else if (!valor.includes(".") && valor.includes(",")) {
            valor = parseFloat(valor.replace(",", "."));
            this.fatura_vet[index].valor = valor;
          }
        }
      });
    },
    conta_a_pagar() {
      let vet = this.fatura_vet;

      vet.forEach((element) => {
        let conta = {
          historico_cod: 1,
          cliente_nome: this.inicial_prePedido.objeto_fornecedor_id.pes_apelido,
          cliente_cod: this.inicial_prePedido.objeto_fornecedor_id.id,
          tipo_pagamento: element.esp_pag.id,
          num_doc: element.n_doc,
          valor: element.valor,
          qtd_parcelas: this.fatura_vet.length,
          emissao: "2023-02-02",
          data_parcela: "2023-02-02",
          data_fatura: "2023-02-02",
          cod_cupom: 1,
          empresa_id: 1,
          user_id: 1,
          debito: 1,
          credito: 1,
          obs: "ola",
        };
        //conta.cliente_nome = this.inicial_prePedido.pes_apelido
        // conta.tipo_pagamento = element.esp_pag
        console.log(element);
        //console.log(element)
        this.post_conta(conta);
      });
    },
    async get_pagamentos_fornecedores() {
      try {
        let response = await http.get(
          "/regPag?empresa_id=" + this.currentEmpresa.id
        );
        if (response.status === 200) {
          this.saldos = response.data;
          // console.log(this.pessoas)
          setTimeout(() => {
            //this.offLoader();
          }, 2000); // tempo para carregar a tela;
        } else {
          //this.p_contas = []; // zera o array;
          this.makeToast("danger", "Erro ao carregar lista");
          //this.offLoader();
        }
      } catch (error) {
        //this.offLoader();
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error.response);
          }
        }
      }
    },
    novo_total(objeto) {
      // this.inicial_prePedido.itensprepedido[
      //   this.inicial_prePedido.itensprepedido.length - 1
      // ].total
      console.log(objeto);
      console.log("entrou em novo total");
      let saldo = objeto.saldo;
      if (typeof saldo === "string") {
        if (saldo.includes(".") && saldo.includes(",")) {
          saldo = parseFloat(saldo.replace(".", "").replace(",", "."));
        } else if (saldo.includes(",") && !saldo.includes(".")) {
          saldo = parseFloat(saldo.replace(",", "."));
        }
      }
      if (this.select_saldo > 0) {
        var soma = 0;
        soma = this.inicial_prePedido.total_pedidos - saldo;
        this.total2 = this.formatterCurrBR(soma);
      } else {
        soma = this.inicial_prePedido.total_pedidos + saldo;
        this.total2 = this.formatterCurrBR(soma);
      }
    },
    setPut() {
      console.log("o ultimo id é == " + this.ultimo_id);

      this.inicial_prePedido.id = this.ultimo_id;
      this.enviar_parcelas();
      this.inicial_prePedido.faturado = 1;
      this.converte_valor_vet_float();
      // console.log(this.inicial_prePedido.vet);
      // console.log(this.inicial_prePedido.vet[0].qtd_final);
      this.$emit("setPut", this.inicial_prePedido);
    },
    converte_valor_vet_float() {
      //let vet_clone_itens = this.inicial_prePedido.vet;
      let vet_new = this.new_vet_pre_itens;

      console.log("estou no converte vet valor");
      /// console.log(vet_new)
      vet_new.forEach((item, index) => {
        this.inicial_prePedido.vet[index].quantidade = item.quantidade;
        // console.log(this.inicial_prePedido.vet[index].quantidade)
        console.log(this.inicial_prePedido.vet[index].quantidade);
      });
      //new_vet_pre_itens
      vet_new.forEach((item, index) => {
        this.new_vet_pre_itens[index].qtd_final = item.qtd_final;
        this.inicial_prePedido.vet[index].qtd_final =
          item.quantidade_rendimento;
      });
      //let vetor = this.itens_d_insert;

      // vet_clone_itens.forEach((item, index) => {
      //   let preco = item.preco;
      //   let valor = item.valor;
      //   let qtd_final = item.qtd_final;

      //   if (typeof preco == "string") {
      //     if (preco.includes(".") && preco.includes(",")) {
      //       preco = parseFloat(preco.replace(".", "").replace(",", "."));
      //       this.itens_d_insert[index].preco = preco;
      //       this.new_vet_pre_itens[index].preco = preco;
      //     } else if (preco.includes(",") && !preco.includes(".")) {
      //       preco = parseFloat(preco.replace(",", "."));
      //       this.itens_d_insert[index].preco = preco;
      //       this.new_vet_pre_itens[index].preco = preco;
      //     }
      //   } else {
      //     this.itens_d_insert[index].preco = preco;
      //     this.new_vet_pre_itens[index].preco = preco;
      //   }
      //   if (typeof valor == "string") {
      //     if (valor.includes(".") && valor.includes(",")) {
      //       valor = parseFloat(valor.replace(".", "").replace(",", "."));
      //       this.itens_d_insert[index].valor = valor;
      //       this.new_vet_pre_itens[index].valor = valor;
      //     } else if (valor.includes(",") && !valor.includes(".")) {
      //       valor = parseFloat(valor.replace(",", "."));
      //       this.itens_d_insert[index].valor = valor;
      //       this.new_vet_pre_itens[index].valor = valor;
      //     }
      //   } else {
      //     this.itens_d_insert[index].valor = valor;
      //     this.new_vet_pre_itens[index].valor = valor;
      //   }
      //   if (typeof qtd_final == "string") {
      //     if (qtd_final.includes(".") && qtd_final.includes(",")) {
      //       qtd_final = parseFloat(
      //         qtd_final.replace(".", "").replace(",", ".")
      //       );
      //       this.itens_d_insert[index].qtd_final = qtd_final;
      //       this.new_vet_pre_itens[index].qtd_final = qtd_final;
      //     } else if (qtd_final.includes(",") && !qtd_final.includes(".")) {
      //       qtd_final = parseFloat(qtd_final.replace(",", "."));
      //       this.itens_d_insert[index].qtd_final = qtd_final;
      //       this.new_vet_pre_itens[index].qtd_final = qtd_final;
      //     }
      //   } else {
      //     this.itens_d_insert[index].qtd_final = qtd_final;
      //     this.new_vet_pre_itens[index].qtd_final = qtd_final;
      //   }
      // });
    },
    async get_itens_prepedidos() {
      try {
        let response = await http.get(
          "/item_pre_pedido?prepedido_id=" + this.ultimo_id
        );
        if (response.status === 200) {
          this.new_vet_pre_itens = response.data;
          console.log(this.new_vet_pre_itens);
        } else {
          //this.fornecedores_pessoa = []; // zera o array;
          this.makeToast("danger", "Erro ao carregar lista");
          // this.offLoader();
        }
      } catch (error) {
        //this.offLoader();

        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error.response);
          }
        }
      }
    },
    // usado para fecahr a fatura; tem outro função para isso também
    seleciona_ultimo_id() {
      let tamanho_vet_pre_pedido = this.pre_pedidos.length;
      // this.ultimo_id = tamanho_vet_pre_pedido;
      this.ultimo_id = this.pre_pedidos[tamanho_vet_pre_pedido - 1].id;
      this.get_itens_prepedidos();
    },

    async get_ultimo_prepedido() {
      try {
        let response = await http.get(
          "/pre-pedido?empresa_id=" + this.currentEmpresa.id
        );
        if (response.status === 200) {
          this.pre_pedidos = response.data;
          this.seleciona_ultimo_id();
        } else {
          //this.fornecedores_pessoa = []; // zera o array;
          this.makeToast("danger", "Erro ao carregar lista");
          // this.offLoader();
        }
      } catch (error) {
        //this.offLoader();

        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error.response);
          }
        }
      }
    },
    back() {
      this.$emit("back");
    },
    select_conta(objeto, index) {
      this.fatura_vet[index].conta = objeto.conta;
      this.fatura_vet[index].conta_id = objeto.conta.id;
    },
    Delete_fatura() {
      this.fatura_vet.pop();
    },
    gerar_parcelas() {
      //let parcela = { ...this.fatura_parcela }

      //this.total_pedidos
      //inicial_prePedido.total_pedidos
      let total_pedidos = this.formatterCurrBR(
        this.inicial_prePedido.total_pedidos
      );
      console.log();
      if (total_pedidos != null) {
        const total = parseFloat(
          total_pedidos.replace(/\./g, "").replace(",", ".")
        );
        const resultado = this.formatterCurrBR(total / this.gerar.dividir);
        // esvazia o vetor toda veze que chammo a função
        this.fatura_vet = [];
        for (let i = 1; i <= this.gerar.dividir; i++) {
          const parcela1 = new Date();
          parcela1.setDate(parcela1.getDate() + 30 * i);
          const ano = parcela1.getFullYear();
          const mes = parcela1.getMonth() + 1; // Lembrando que os meses são zero-based
          const dia = parcela1.getDate() + 1;
          const dataFormatada = `${ano}-${mes.toString().padStart(2, "0")}-${dia
            .toString()
            .padStart(2, "0")}`;
          this.fatura_vet.push({
            pre_pedidos_id: this.ultimo_id,
            vencimento: dataFormatada,
            valor: resultado,
          });
        }
      } else {
        this.makeToast("danger", "inserir os pedidos na tela de estabelcer");
      }
    },
    makeToast(variant = null, message = "Error generic") {
      this.counter++;
      this.$bvToast.toast(message, {
        title: `Notificação`,
        toaster: "b-toaster-bottom-right",
        variant: variant,
        solid: true,
        appendToast: true,
      });
    },
    select_tp_pagamento(objeto, index) {
      this.fatura_vet[index].tp_pagamento = objeto.esp_pag;
      this.fatura_vet[index].tp_pagamento_id = objeto.esp_pag.id;
      console.log(this.fatura_vet[index].esp_pag);
    },
    // para selecionar o vencimento da tabela dinamica
    select_vencimento(objeto, index) {
      this.fatura_vet[index].vencimento = objeto.vencimento;
      console.log(this.fatura_vet[index].vencimento);
    },
    dlt_parcelas() {},
    faturando_fecha() {
      this.$emit("faturando_fecha");
    },

    async get_pagamentos() {
      try {
        let response = await http.get(
          "/tipopagamento?empresa_id=" + this.currentEmpresa.id
        );
        if (response.status === 200) {
          this.pagamentos = response.data;
          // console.log(this.p_contas)
          setTimeout(() => {
            //this.offLoader();
          }, 2000); // tempo para carregar a tela;
        } else {
          this.pagamentos = []; // zera o array;
          this.makeToast("danger", "Erro ao carregar lista");
          //this.offLoader();
        }
      } catch (error) {
        //this.offLoader();
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error.response);
          }
        }
      }
    },
    async get_pesssoas() {
      try {
        let response = await http.get(
          "/pessoa?empresa_id=" + this.currentEmpresa.id
        );
        if (response.status === 200) {
          this.pessoas = response.data;
          // console.log(this.pessoas)
          setTimeout(() => {
            //this.offLoader();
          }, 2000); // tempo para carregar a tela;
        } else {
          //this.p_contas = []; // zera o array;
          this.makeToast("danger", "Erro ao carregar lista");
          //this.offLoader();
        }
      } catch (error) {
        //this.offLoader();
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error.response);
          }
        }
      }
    },
  },
};
</script>
<style scoped>
.largura {
  width: 130px;
}
.larg_mult {
  width: 130px;
}
.estilo_input {
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.estilo_input1 {
  width: 130px;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
</style>