<template>
  <b-modal
    v-model="recebendo_bol"
    size="xl"
    title="Confirmar Recebimento"
    hide-footer
    @hidden="back()"
  >
    <div class="row d-flex justify-content-center align-items-center">
      <div class="col-md-12 text-center">
        <h1>Desejar Confirmar o Recebiemnto ?</h1>
      </div>
    </div>
    <div class="row d-flex justify-content-center align-items-center">
      <div class="col-md-12 text-center">
        <button class="btn btn-secondary" @click="recebimento_fecha()">
          Confirmar
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    modal_recebimento: { type: Boolean },
    item_obj1: { type: Object },
  },
  data() {
    return {
      recebendo_bol: null,
      recebimento: {},
    };
  },
  created() {
    this.recebimento = this.item_obj1;
    this.recebendo_bol = this.modal_recebimento;
  },
  mounted() {
    console.log(this.item_obj1)
  },
  methods: {
    makeToast(variant = null, message = "Error generic") {
      this.counter++;
      this.$bvToast.toast(message, {
        title: `Notificação`,
        toaster: "b-toaster-bottom-right",
        variant: variant,
        solid: true,
        appendToast: true,
      });
    },
    back() {
      this.$emit("back");
    },
    recebimento_fecha() {
      this.recebimento.recebido = 1;
      this.$emit("recebida", this.recebimento);
    },
  },
};
</script>

<style>
</style>