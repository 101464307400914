<template>
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-md-12">
          <input
            v-model="t_chave"
            type="radio"
            value="0"
            @change="verifica_chave($event)"
          />
          <Label class="mr-4">Tenho Chave de Acesso</Label>
          <input
            v-model="t_chave"
            type="radio"
            value="1"
            @change="verifica_chave($event)"
          />
          <Label>Não Tenho Chave de Acesso</Label>
        </div>
      </div>
      <div class="row mb-2 mt-2">
        <div class="col-md-12">
          <label for="">CNPJ</label>
          <input
            type="text"
            v-model="Manifesto.cnpj"
            class="form-control text-left"
          />
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-md-12">
          <Label>{{ nsu }}</Label>
          <input type="text" class="form-control text-left" />
        </div>
        <div class="col-md-12">
          <Label>{{ pesquisa }}</Label>
          <multiselect
            v-model="select_pesquisa"
            :options="opcoes"
            label="nome"
          >
          </multiselect>
        </div>
        <div class="col-md-12">
          <Label>Emissor da NF-e:</Label>
          <multiselect v-model="select_emi_nfe" :options="opcoes2" label="nome">
          </multiselect>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-12">
          <button class="btn btn-primary" @click.prevent="busca()">
            Pesquisar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import { http } from "../../../helpers/easyindustriaapi/config";
export default {
  components: {
    Multiselect,
  },
  props: {
    show_manifesto: { type: Boolean },
  },
  data() {
    return {
      nsu: "NSU",
      t_chave: null,
      name: "ola",
      modal_manifesto: null,
      pesquisa: "Pesquisa",
      opcoes: [
        {
          id: 210210,
          nome: "Ciência da Operação",
        },
        {
          id: 210200,
          nome: "Confirmação da Operação",
        },
        {
          id: 210220,
          nome: "Desconhecimento da Operação",
        },
        {
          id: 210240,
          nome: "Operação Não Realziada",
        },
      ],

      select_pesquisa: null,
      select_emi_nfe: null,
      opcoes2: [
        {
          id: 1,
          nome: "Todas os Emitentes",
        },
        {
          id: 2,
          nome: "Excluir emitentes da mesma empresa(Mesmo CNPJ base)",
        },
      ],

      Manifesto: {
        uf: "PE",
        cnpj: "28267948000127",
      },
      show_input: false,
    };
  },
  created() {
    this.modal_manifesto = this.show_manifesto;
  },
  methods: {
    verifica_chave(event) {
      console.log(event.target.value);
      let resultado = event.target.value;

      if (resultado == 0) {
        this.pesquisa = "Realizar Manifestação";
        (this.opcoes = [
          {
            id: 210210,
            nome: "Ciência da Operação",
          },
          {
            id: 210200,
            nome: "Confirmação da Operação",
          },
          {
            id: 210220,
            nome: "Desconhecimento da Operação",
          },
          {
            id: 210240,
            nome: "Operação Não Realziada",
          },
        ]),
          (this.show_input = true);
        this.nsu = "Chave";
      } else if (resultado == 1) {
        this.pesquisa = "Pesquisa";
        (this.opcoes = [
          {
            id: 1,
            nome: "Todas as Nf-e ",
          },
          {
            id: 2,
            nome: "Apenas NF-e sem Manifestação de Confirmação, Desconhecimento ou Não realizada",
          },
          {
            id: 3,
            nome: "Apenas NF-e sem Manifestação de Ciência,Confirmação, Desconhecimento ou Não realizada",
          },
        ]),
          (this.nsu = "NSU");
        this.show_input = false;
      }
    },
    busca() {
      let manifesto = this.Manifesto;
      //console.log(manifesto);
      this.PostNsu(manifesto);
    },
    makeToast(variant = null, message = "Error generic") {
      this.counter++;
      this.$bvToast.toast(message, {
        title: `Notificação`,
        toaster: "b-toaster-bottom-right",
        variant: variant,
        solid: true,
        appendToast: true,
      });
    },
    async PostNsu(nsu) {
      console.log("estou no NSU ");
      console.log(nsu);
      // id
      // DescricaoUn
      // un_codigo
      // empresa_id

      // NSU.empresa_id = this.currentEmpresa.id;
      // NSU.user_id = this.currentUser.id;
      //   let nsu = {
      //     cnpj: "28267948000127",
      //     uf: "PE",
      //   };

      console.log(nsu);
      // this.makeToast("success", "feito consulta NSU");
      //this.onLoader();

      //console.log(NSU)
      try {
        let response = await http.post("/nfe/nsu", nsu);
        if (response) {
          // this.offLoader();
          if (response.status === 200) {
            // console.log(response.data);
            //this.getData();
            //this.abri_menu_modal()
            this.inserindo = false;
            this.makeToast("success", "feito consulta NSU");
            console.log(response.data);
          }
        }
      } catch (error) {
        //this.offLoader();
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error);
          }
        }
      }
    },
    back() {
      this.$emit("back");
    },
    post_manifesto() {},
  },
};
</script>

<style>
</style>